import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
const CustomTotalCard = (props) => {
  const { title, count } = props;
  return (
    <div className="customCard">
      <Card sx={{
        backgroundColor: '#142328',
        borderRadius: '15px',
        height:'100px'
      }}>
        <CardContent>
          <Box>
            <Typography variant="h5" component="h2" sx={{
              fontSize: '18px',
              fontWeight: '400',
              color: '#ffffff',
            }}>
              {title}
            </Typography>
            <Typography variant="h6" component="h3" sx={{
              borderRadius: '10px',
              fontSize: '18px',
              fontWeight: '400',
              color: '#ffffff',
              margin: '20px 0px 0px 0px',
            }}>
              {count}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
export default CustomTotalCard;
