import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar/Sidebar";
import { partnerLayoutRoute } from "../../routes";
const PartnerLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem('YandiWeb'))
    if (userCheck?.role !== Number(3)) {
      navigate('/login');
    }
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <Routes>
        {partnerLayoutRoute.map((route, index) => {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={
                route.sidebar ? (
                  <>
                    <Sidebar />
                    <Box
                      sx={{
                        width: "calc(100% - 300px)", height: "100vh", overflow: "auto",
                        padding:'20px 0px'
                      }}
                    >
                      {route.component}
                    </Box>
                  </>
                ) : (
                  <Box
                  sx={{
                    width: "100%", height: "100vh", overflow: "auto"
                  }}
                  >
                    {route.component}
                  </Box>
                )
              }
            />
          );
        })}
      </Routes>
    </Box>
  );
};

export default PartnerLayout;
