import React from "react";
import { Dialog, DialogContent } from "@mui/material";

const CustomModal = ({ show, children, onCloseClick }) => {
  return (
    <Dialog open={show} onClose={onCloseClick} fullWidth maxWidth="sm" centered>
      <DialogContent className="py-3 px-5">
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
