import { Box, Typography } from "@mui/material";
import {useState } from "react";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import {useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import AxiosInstance from "../../../Config/AxiosInstance";
import Swal from "sweetalert2";
const Verification = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const VerificationValidation = useFormik({
    initialValues: {
      otp: null,
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("OTP Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const params = {
        email: JSON.parse(window.localStorage.getItem("info"))?.email,
        otp: values.otp,
        fp_token: JSON.parse(window.localStorage.getItem("info"))?.fp_token,
      };
      setBusy(true);
      try {
        const response = await AxiosInstance.post("/auth/check-otp", params);
        if (response && response?.data.s === 1) {
          navigate("/change-password");
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "success",
            text: response?.data?.m,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "error",
            text: response?.data?.m,
          });
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        setBusy(false);
        resetForm();
      }
    },
  });
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        p: 6,
        boxShadow: "0px 4px 4px 0px #00000040",
        border: "none",
        width: 487,
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "42px", fontWeight: "700" }}
        >
          We send you a code
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "26px", fontWeight: "500" }}
        >
          Please, enter it below to verify your email
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}
        >
          {JSON.parse(window.localStorage.getItem("info"))?.email}
        </Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            VerificationValidation.handleSubmit();
            return false;
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <OtpInput
              name="otp"
              value={VerificationValidation.values.otp}
              onChange={(otp) =>
                VerificationValidation.setFieldValue("otp", otp)
              }
              numInputs={4}
              isInputNum
              renderSeparator={<span style={{ paddingRight: "20px" }}></span>}
              renderInput={(props, index) => {
                const { value } = props;
                return (
                  <input
                    {...props}
                    name={`otp-${index}`}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "6px solid  #AAF7D1",
                      boxShadow: "2px 4px 4px 1px ",
                      textAlign: "center",
                      fontSize: "24px",
                      borderRadius: "10px",
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center" }} mt={3}>
            <CustomButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !(
                  VerificationValidation.isValid && VerificationValidation.dirty
                )
              }
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                height: "56px",
                maxWidth: "180px",
                textTransform: "unset",
                fontFamily: "Inter",
                backgroundColor: "#000000",
              }}
              btnTitle="Verify Otp"
              loading={busy}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Verification;
