import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
  } from "@mui/material";
  import { useState } from "react";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import { VisibilityOff, Visibility } from "@mui/icons-material";
  import CustomButton from "../../../Components/CustomButton/CustomButton";
  import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../Config/AxiosInstance";
import Swal from "sweetalert2";
  const ChangePassword = () => {
    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () =>
      setConfirmPasswordShow((show) => !show);
    const ChangePasswordValidation = useFormik({
      initialValues: {
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: Yup.object({
        newPassword: Yup.string()
          .required("New Password is required")
          .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least one letter, one number, and one symbol. Minimum length is 8 characters."
          ),
        confirmPassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
      }),
      onSubmit: async (values, { resetForm }) => {
        const params = {
          password: values.newPassword,
          fp_token: JSON.parse(localStorage.getItem('info'))?.fp_token,
        };
        setBusy(true);
        try {
          const response = await AxiosInstance.post("/auth/change-password", params);
          if (response && response?.data.s === 1) {
              navigate("/login");
              localStorage.clear();
              Swal.fire({
                position: "center",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                title: "success",
                text: response?.data?.m,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response?.data?.m,
              });
            }

      } catch (error) {
          throw error;
      } finally {
          setBusy(false);
          resetForm()
      }
      },
    });
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 6,
          boxShadow: "0px 4px 4px 0px #00000040",
          border: "none",
          width: 487,
          borderRadius: "10px",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "42px",
            fontWeight: "700",
            marginBottom: "24px",
          }}
        >
          Change Password
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              ChangePasswordValidation.handleSubmit();
              return false;
            }}
          >
            <Box>
              <TextField
                name="newPassword"
                type={showPassword ? "text" : "password"}
                onChange={ChangePasswordValidation.handleChange}
                onBlur={ChangePasswordValidation.handleBlur}
                value={ChangePasswordValidation.values.newPassword}
                onKeyUp={ChangePasswordValidation.handleBlur}
                placeholder="New Password"
                invalid={
                  ChangePasswordValidation.touched.newPassword &&
                  ChangePasswordValidation.errors.newPassword
                    ? true
                    : false
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `${
                        ChangePasswordValidation.touched.newPassword &&
                        ChangePasswordValidation.errors.newPassword
                          ? "1px solid red"
                          : "none"
                      }`, // Corrected line
                      boxShadow: "0px 4px 4px 0px #00000040",
                    },
                    height: "67px",
                    "& ::placeholder": {
                      fontSize: "17px",
                      fontWeight: 400,
                    },
                  },
  
                  marginTop: "5px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="outlined-required"
              />
              {ChangePasswordValidation.touched.newPassword &&
              ChangePasswordValidation.errors.newPassword ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {ChangePasswordValidation.errors.newPassword}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <TextField
                name="confirmPassword"
                type={confirmPasswordShow ? "text" : "password"}
                onChange={ChangePasswordValidation.handleChange}
                onBlur={ChangePasswordValidation.handleBlur}
                value={ChangePasswordValidation.values.confirmPassword}
                onKeyUp={ChangePasswordValidation.handleBlur}
                invalid={
                  ChangePasswordValidation.touched.confirmPassword &&
                  ChangePasswordValidation.errors.confirmPassword
                    ? true
                    : false
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `${
                        ChangePasswordValidation.touched.confirmPassword &&
                        ChangePasswordValidation.errors.confirmPassword
                          ? "1px solid red"
                          : "none"
                      }`, // Corrected line
                      boxShadow: "0px 4px 4px 0px #00000040",
                    },
                    height: "64px",
                    "& ::placeholder": {
                      fontSize: "17px",
                      fontWeight: 400,
                    },
                  },
  
                  marginTop: "5px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {confirmPasswordShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="outlined-required"
                placeholder="Confirm Password"
              />
              {ChangePasswordValidation.touched.confirmPassword &&
              ChangePasswordValidation.errors.confirmPassword ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {ChangePasswordValidation.errors.confirmPassword}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }} mt={1}>
              <CustomButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={
                  !(
                    ChangePasswordValidation.isValid &&
                    ChangePasswordValidation.dirty
                  )
                }
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  height: "56px",
                  maxWidth: "261px",
                  textTransform: "unset",
                  fontFamily: "Inter",
                  backgroundColor: "#000000",
                }}
                btnTitle="Change Password"
                loading={busy}
              />
            </Box>
          </form>
        </Box>
      </Box>
    );
  };
  
  export default ChangePassword;
  