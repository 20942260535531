import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-quill/dist/quill.snow.css";
import AxiosInstance from "../../../../Config/AxiosInstance";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import Swal from "sweetalert2";
const TermsAndCondition = () => {
  const ref = useRef();
  const [busy, setBusy] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const getTermsAndCondition = async () => {
    try {
      setBusy(true);
      const response = await AxiosInstance.get("/admin/general-docs/get");
      if (response && response?.data?.s === 1) {
        const termsCondition = response?.data?.r?.terms_condition || "";
        formik.setValues({
          terms_condition: termsCondition,
        });
        setEditMode(true);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.data?.m,
        });
      }
    } catch (error) {
      toast.error("Error occurred while fetching privacy policy");
    } finally {
      setBusy(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      terms_condition: "",
    },
    validationSchema: Yup.object({
      terms_condition: Yup.string().required("Terms Condition Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusy(true);
      if (busy) {
        return;
      } else {
        try {
          const response = await AxiosInstance.post(
            "/admin/general-docs/update",
            {
              terms_condition: values.terms_condition,
            }
          );
          if (response && response?.data?.s === 1) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.data?.m,
            });
            getTermsAndCondition();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
              title: "error",
              text: response?.data?.m,
            });
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          resetForm();
          setBusy(false);
        }
      }
    },
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box sx={{ position: "relative" }}>
        {isEditMode && (
          <IconButton
            type="button"
            sx={{
              position: "absolute",
              top: "2px",
              right: "10px",
              width: "40px",
              height: "40px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
              border: "1px solid #000",
              cursor: "pointer",
            }}
            onClick={() => setEditMode(false)}
          >
            <EditIcon
              sx={{
                fontSize: "20px",
                color: "#000000",
              }}
            />
          </IconButton>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ReactQuill
            placeholder="Description"
            value={formik.values.terms_condition}
            ref={ref}
            readOnly={isEditMode}
            modules={{
              toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                  { align: "right" },
                  { align: "center" },
                  { align: null },
                  { align: "justify" },
                ],
              ],
            }}
            onChange={(content, delta, source, editor) => {
              content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
              formik.setFieldValue("terms_condition", content);
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <CustomButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={
                !formik.values.terms_condition.replace(/<[^>]+>/g, "").trim() ||
                isEditMode
              }
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                height: "50px",
                maxWidth: "160px",
                margin: "0 auto",
                textTransform: "unset",
                fontFamily: "Inter",
                background: "#00E676",
                "&:hover": {
                  background: "#00E676",
                },
              }}
              btnTitle="Save"
              loading={busy}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
