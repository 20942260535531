import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ chartData }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const labels = chartData.map((data) => Object.keys(data)[0]);
    const dataPoints = chartData.map((data) => parseFloat(Object.values(data)[0]));

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Revenue",
          data: dataPoints,
          borderColor: "rgba(0, 230, 118, 1)",
          fill: false,
        },
      ],
    };

    const options = {
      hoverRadius: 12,
      hoverBackgroundColor: "yellow",
      interaction: {
        mode: "nearest",
        intersect: true,
        axis: "x",
      },
      plugins: {
        tooltip: {
          enabled: true,
        },
      },
      animations: {
        radius: {
          duration: 5,
          easing: "linear",
          loop: (context) => context.active,
        },
      },
      scales: {
        x: {
          type: "category",
          position: "bottom",
          grid: {
            display: false, 
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      elements: {
        line: {
          tension: 0.1,
          fill: false,
        },
      },
    };
    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: data,
      options: options,
    });
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <canvas ref={chartRef} width="100%" height="18px"></canvas>
  );
};

export default LineChart;
