import React, { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomModel from '../../../Components/CustomModel/CustomModel';
import BrandLogo from "../../../Assets/Images/logo.png";
import ICON1 from "../../../Assets/Images/PartnerImages/dashboardIcon.png";
import ICON2 from "../../../Assets/Images/PartnerImages/driversIcon.png";
import ICON3 from "../../../Assets/Images/PartnerImages/priceIcon.png";
import ICON4 from "../../../Assets/Images/PartnerImages/settingIcon.png";
import ICON5 from "../../../Assets/Images/PartnerImages/LogoutIcon.png";
import "./Sidebar.css";
import Swal from "sweetalert2";
const Sidebar = () => {
  const navigate = useNavigate();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const restaurauntNavlink = [
    {
      path: "/partner/dashboard",
      icon: ICON1,
      title: "Dashboard",
    },
    {
      path: "/partner/drivers",
      icon: ICON2,
      title: "Drivers",
    },
  ];
  const handleLogout = () => {
    setOpenConfirmModel(true);
    Swal.fire({
      title: "Are you sure?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Logged Out",
          text: "You have been successfully logged out.",
        });
        localStorage.clear();
        navigate("/login");
      }
    });
  };
  return (
    <Fragment>
      <div className={"left-sidebar"}>
        <div className="sidebarContainer">
          <div className="brand-logo d-flex align-items-center justify-content-center">
            <img src={BrandLogo} width="100px" alt="icon" />
          </div>
          <div className="navigationContent">
            <div className="scrollNav">
              <ul className="nav_Item">
                {restaurauntNavlink.map((elem, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        to={elem.path}
                        className={(navClass) =>
                          navClass.isActive ? "submenuLink" : "submenuLink"
                        }
                        exact={elem.exact}
                      >
                        <div
                          className={
                            elem.path === window.location.pathname
                              ? "active iconLink"
                              : "iconLink"
                          }
                        >
                          <img src={elem.icon} className="icon" alt="" />
                          <span className="text">{elem.title}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="bottomDetails">
              <div className="action" onClick={() => handleLogout()}>
                <img src={ICON5} className="logoutIcon" alt="logout" />
                <span>Log Out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
