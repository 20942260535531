import React, { Fragment, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import "./Setting.css";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition/TermsAndCondition";
import Faq from "./Faq/Faq";
import Cuisine from "./Cuisine/Cuisine";

const Setting = () => {
  const [activeTab, setActiveTab] = useState("privacyPolicy");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Fragment>
      <div className="setting">
        <div className="titleSection">
          <h4 className="title">App Links</h4>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "privacyPolicy" ? "active" : ""}
              onClick={() => toggleTab("privacyPolicy")}
            >
              Privacy Policy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "termsAndCondition" ? "active" : ""}
              onClick={() => toggleTab("termsAndCondition")}
            >
              Terms & Condition
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "faq" ? "active" : ""}
              onClick={() => toggleTab("faq")}
            >
              FAQ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "cuisine" ? "active" : ""}
              onClick={() => toggleTab("cuisine")}
            >
              Cuisine
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="privacyPolicy">
            <PrivacyPolicy />
          </TabPane>
          <TabPane tabId="termsAndCondition">
            <TermsAndCondition />
          </TabPane>
          <TabPane tabId="faq">
            <Faq />
          </TabPane>
          <TabPane tabId="cuisine">
            <Cuisine />
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
};

export default Setting;
