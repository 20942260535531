import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Autocomplete from "react-google-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Box, Typography, IconButton, Grid, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/admin/gallery.png";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import Constant from "../../../../utils/constant";
import MapComponent from "../../../../Components/MapAllLocation/MapComponent";
import AxiosInstance from "../../../../Config/AxiosInstance";
const RestaurantAdd = () => {
  const uploadCoverButtonRef = useRef(null);
  const uploadIdCardCoverButtonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [typeCheck, setTypeCheck] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [idCardName, setIdCardName] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [regionLoc, setRegionLoc] = useState("");
  const [cityLoc, setCityLoc] = useState("");
  const [pinLoc, setPinLoc] = useState("");
  const [addressAuto, setAddressAuto] = useState("");
  const [latlng, setLatLng] = useState({ lat: 22.3511148, lng: 78.6677428 });
  const [mapAllLocation, setMapAllLocation] = useState([]);
  const [restuarantId, setRestuarantId] = useState(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [restuarantEditDetails, setRestuarantEditDetails] = useState({});
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
        formik.setFieldValue("coverImage", file);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: "File size exceeds the limit of 5MB",
        });
      }
    }
  };
  const handleIdCardOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setIdCard(file);
        setIdCardName(file.name);
        formik.setFieldValue("idCard", file);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: "File size exceeds the limit of 5MB",
        });
      }
    }
  };
  const handleTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("cover-upload");
      fileInput.click();
    }
  };
  const handleIdCardTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("id-card");
      fileInput.click();
    }
  };
  const getAddress = (lat, long) => {
    if (lat && long) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${Constant.GOOGLEMAPKEY.ApiKey}`
      )
        .then((res) => res.json())
        .then((address) => {
          setLatitude(address.results[0]?.geometry?.location?.lat);
          setLongitude(address.results[0]?.geometry?.location?.lng);

          const addressAuto = address.results?.find((i) => {
            return i.types.some((i) => i === "route");
          });
          setAddressAuto(addressAuto?.formatted_address);

          const regionMap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "sublocality");
          });
          setRegionLoc(regionMap?.long_name);
          const cityMap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "administrative_area_level_3");
          });
          setCityLoc(cityMap?.long_name);
          const PINmap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "postal_code");
          });
          setPinLoc(PINmap?.long_name);
        });
    }
  };
  useEffect(() => {
    if (typeCheck === "edit") {
      if (latitude && longitude) {
        const place = {
          geometry: {
            location: {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            },
          },
          formatted_address: addressAuto,
        };
        handlePlaceSelected(place);
      }
    }
  }, [latitude, longitude]);
  useEffect(() => {
    if (typeCheck === "edit") {
      if (latitude && longitude) {
        getAddress(latitude, longitude);
      }
    }
  }, [latitude, longitude]);
  const handlePlaceSelected = (place) => {
    if (place.geometry) {
      let lat, lng;
      if (typeof place.geometry.location.lat === "function") {
        lat = place.geometry.location.lat();
        lng = place.geometry.location.lng();
      } else {
        lat = place.geometry.location.lat;
        lng = place.geometry.location.lng;
      }
      setLatLng({ lat, lng });
      setAddressAuto(place.formatted_address);
      setLatitude(lat);
      setLongitude(lng);
    } else {
      const lat = place.lat;
      const lng = place.lng;
      setLatLng({ lat, lng });
      setAddressAuto(place.address);
      setLatitude(lat);
      setLongitude(lng);
    }
  };
  useEffect(() => {
    getAddress(latitude, longitude);
  }, []);
  useEffect(() => {
    if (addressAuto) {
      formik.setFieldValue("location", addressAuto);
    }
  }, [addressAuto]);
  useEffect(() => {
    if (typeCheck === "edit" && addressAuto) {
      const autocompleteInput = document.getElementById("autocomplete");
      if (autocompleteInput) {
        autocompleteInput.value = addressAuto;
      }
    }
  }, [addressAuto, typeCheck]);
  useEffect(() => {
    if (location?.state?.restuarantId) {
      const restuarantType = location?.state?.type;
      setTypeCheck(restuarantType);
      getResuarantDetails(location?.state?.restuarantId);
      setRestuarantId(location?.state?.restuarantId);
    }
  }, [location?.state?.restuarantId]);
  useEffect(() => {
    getAddress(latitude, longitude);
  }, []);
  const getResuarantDetails = async (restuarantId) => {
    setIsLoading(true);
    setBusy(true);
    try {
      const res = await AxiosInstance.get(
        `/user/get-by-id?user_id=${restuarantId}`
      );
      if (res?.data?.s === 1) {
        const restaurantData = res.data?.r;
        setRestuarantEditDetails(restaurantData)
        const userAddress = restaurantData.user_address[0];
        const docReference = JSON.parse(
          restaurantData?.user_document[0]?.doc_reference || "{}"
        );
        formik.setValues({
          restaurantName: restaurantData.restaurant_name || "",
          shortDescription: restaurantData.description || "",
          currentAddress: userAddress?.address || "",
          phoneNumber: restaurantData.phno || "",
          email: restaurantData.email || "",
          businessRegistrationNumber:
            docReference.businessRegistrationNumber || "",
          taxidentificationNumber: docReference.taxidentificationNumber || "",
          phno_cc: restaurantData?.phno_cc.startsWith("+")
            ? restaurantData.phno_cc.slice(1)
            : restaurantData.phno_cc,
          address: userAddress?.address,
        });
        if (restaurantData?.profile_img) {
          setCoverImageUploadPreview(
            `https://api.yandi.app${restaurantData?.profile_img}`
          );
          formik.setFieldValue('coverImage', restaurantData?.profile_img);
        }
        if (restaurantData?.user_document[0]?.document) {
          const documentUrl = restaurantData.user_document[0].document;
          const documentFileName = documentUrl.substring(
            documentUrl.lastIndexOf("/") + 1
          );
          setIdCardName(documentFileName);
          formik.setFieldValue('idCard', documentFileName);
        }
        if (userAddress) {
          setAddressAuto(userAddress?.address);
          setLatLng({
            lat: userAddress?.lat,
            lng: userAddress?.lang,
          });
        }
      }
      setIsLoading(false);
      setBusy(false);
    } catch (error) {
      console.error("Error fetching restaurant details:", error);
      setIsLoading(false);
      setBusy(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      restaurantName: "",
      shortDescription: "",
      currentAddress: "",
      phoneNumber: "",
      email: "",
      password: "",
      businessRegistrationNumber: "",
      taxidentificationNumber: "",
      phno_cc: "",
      address: "",
      coverImage: null,
      idCard: null,
    },
    validationSchema: Yup.object({
      restaurantName: Yup.string().required("Restaurant name is required"),
      shortDescription: Yup.string().required("Short description is required"),
      currentAddress: Yup.string().required("Address is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      ...(typeCheck !== "edit" && {
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .max(20, "Password must be at most 20 characters")
          .required("Password is required"),
      }),
      address: Yup.string().required("Location field is required"),
      businessRegistrationNumber: Yup.string().required(
        "Business registration number is required"
      ),
      taxidentificationNumber: Yup.string().required(
        "Tax identification number is required"
      ),
      coverImage: Yup.mixed().required("restaurant image is required"),
      idCard: Yup.mixed().required("id card image is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      if (typeCheck === "edit") {
        try {
          const formData1 = new FormData();
          formData1.append("user_id", restuarantId);
          formData1.append("restaurant_name", values.restaurantName);
          formData1.append("description", values.shortDescription);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("phno", values.phoneNumber);
          formData1.append("email", values.email);
          if (coverImageUploadFile) {
            formData1.append("profile_img", coverImageUploadFile);
          }
          formData1.append("lat", latlng?.lat);
          formData1.append("lang", latlng?.lng);
          const response1 = await AxiosInstance.post("/user/update", formData1);
          if (response1 && response1?.data?.s === 1) {
            const formData2 = new FormData();
            formData2.append("id", restuarantEditDetails?.user_address[0]?.id);
            formData2.append("type", 1);
            formData2.append("address", values?.currentAddress);
            formData2.append("lat", latlng?.lat);
            formData2.append("lang", latlng?.lng);
            const response2 = await AxiosInstance.post(
              "/user/address/update",
              formData2
            );
            if (response2 && response2?.data?.s === 1) {
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response2?.data?.m,
              });
            }
            const LegalInformation = {
              businessRegistrationNumber: values.businessRegistrationNumber,
              taxidentificationNumber: values.taxidentificationNumber,
            };
            const formData3 = new FormData();
            formData3.append("id", restuarantEditDetails?.user_document[0]?.id);
            if (idCard) {
              formData3.append("document", idCard);
            }
            formData3.append("doc_reference", JSON.stringify(LegalInformation));
            const response3 = AxiosInstance.post(
              "/user/document/update",
              formData3
            );
            if (response3 && response3?.data?.s === 1) {
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response3?.data?.m,
              });
            }
            resetForm();
            setCoverImageUploadPreview(null);
            setCoverImageUploadFile(null);
            setIdCard(null);
            setIdCardName(null);
            navigate("/admin/restaurant");
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response1?.data?.m,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        try {
          const formData1 = new FormData();
          formData1.append("restaurant_name", values.restaurantName);
          formData1.append("description", values.shortDescription);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("phno", values.phoneNumber);
          formData1.append("email", values.email);
          formData1.append("password", values.password);
          if (coverImageUploadFile) {
          formData1.append("profile_img", coverImageUploadFile);
          }
          formData1.append("lat", latlng?.lat);
          formData1.append("lang", latlng?.lng);
          const response1 = await AxiosInstance.post(
            "/admin/add-restaurant",
            formData1
          );
          if (response1 && response1?.data?.s === 1) {
            const formData2 = new FormData();
            formData2.append("user_id", response1?.data?.r?.id);
            formData2.append("type", 1);
            formData2.append("address", values?.currentAddress);
            formData2.append("lat", latlng?.lat);
            formData2.append("lang", latlng?.lng);
            const response2 = await AxiosInstance.post(
              "/user/address/add",
              formData2
            );
            if (response2 && response2?.data?.s === 1) {
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response2?.data?.m,
              });
            }
            const LegalInformation = {
              businessRegistrationNumber: values.businessRegistrationNumber,
              taxidentificationNumber: values.taxidentificationNumber,
            };
            const formData3 = new FormData();
            formData3.append("user_id", response1?.data?.r?.id);
            formData3.append("document", idCard);
            formData3.append("doc_reference", JSON.stringify(LegalInformation));
            const response3 = AxiosInstance.post(
              "/user/document/add",
              formData3
            );
            if (response3 && response3?.data?.s === 1) {
            } else {
              Swal.fire({
                position: "center",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                title: "error",
                text: response3?.data?.m,
              });
            }
            resetForm();
            setCoverImageUploadPreview(null);
            setCoverImageUploadFile(null);
            setIdCard(null);
            setIdCardName(null);
            navigate("/admin/restaurant");
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response1?.data?.m,
            });
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  return (
    <Fragment>
      <TopHeader
        title={typeCheck === "edit" ? "Update Restaurant" : "Add Restaurant"}
        onClick={() => navigate(-1)}
        type="button"
        btnTitle="Submit"
        onSubmit={formik.handleSubmit}
        loading={isLoading}
        showButton={true}
      />
      <Box
        sx={{
          padding: "80px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            textAlign: "left",
            padding: "14px 0px 14px 0px",
            fontFamily: "Poppins",
          }}
        >
          Restuarant Details
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    background: "#F4F4F4",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border:
                      formik.touched.coverImage && formik.errors.coverImage
                        ? "1px solid red"
                        : null,
                  }}
                >
                  {!coverImageUploadPreview && (
                    <label htmlFor="cover-upload">
                      <Button
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          outline: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          textTransform: "capitalize",
                        }}
                        ref={uploadCoverButtonRef}
                        onKeyDown={handleTabKeyPress}
                        type="button"
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            margin: "8px 0px !important",
                          }}
                        >
                          Upload Picture
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          {`(JPG,JPEG,PNG)`}
                        </Typography>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                      </Button>
                    </label>
                  )}
                  {coverImageUploadPreview && (
                    <Box>
                      <img
                        src={coverImageUploadPreview}
                        alt="Uploaded Image"
                        effect="blur"
                        height={"150px"}
                        width={"150px"}
                      />
                      <label htmlFor="cover-upload">
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "50px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoverImageUploadPreview(null);
                            setCoverImageUploadFile(null);
                            formik.setFieldValue('coverImage', null);
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                      </label>
                    </Box>
                  )}
                </Box>
              )}
              {formik.touched.coverImage && formik.errors.coverImage ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.coverImage || "Restaurant image is required"}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="restaurantName"
                  placeholder="Restaurant Name"
                  value={formik.values.restaurantName}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.restaurantName &&
                      formik.errors.restaurantName
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  invalid={
                    formik.touched.restaurantName &&
                    formik.errors.restaurantName
                      ? true
                      : false
                  }
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.restaurantName && formik.errors.restaurantName ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.restaurantName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="shortDescription"
                  placeholder="Short Description"
                  value={formik.values.shortDescription}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.shortDescription &&
                      formik.errors.shortDescription
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  invalid={
                    formik.touched.shortDescription &&
                    formik.errors.shortDescription
                      ? true
                      : false
                  }
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.shortDescription &&
              formik.errors.shortDescription ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.shortDescription}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="currentAddress"
                  placeholder="Address"
                  value={formik.values.currentAddress}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.currentAddress &&
                      formik.errors.currentAddress
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  invalid={
                    formik.touched.currentAddress &&
                    formik.errors.currentAddress
                      ? true
                      : false
                  }
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.currentAddress && formik.errors.currentAddress ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.currentAddress}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Autocomplete
                      placeholder="Location"
                      id="autocomplete"
                      name="address"
                      value={formik.values.address}
                      className="inputClass"
                      componentRestrictions={{ country: "in" }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      apiKey={Constant.GOOGLEMAPKEY.ApiKey}
                      onChange={formik.handleChange}
                      onKeyUp={formik.handleBlur}
                      onPlaceSelected={(place) => {
                        const regionMap = place.address_components?.find(
                          (i) => {
                            return i.types.some((i) => i === "sublocality");
                          }
                        );
                        setRegionLoc(regionMap?.long_name);
                        const cityMap = place.address_components?.find((i) => {
                          return i.types.some(
                            (i) => i === "administrative_area_level_3"
                          );
                        });
                        setCityLoc(cityMap?.long_name);
                        const PINmap = place.address_components?.find((i) => {
                          return i.types.some((i) => i === "postal_code");
                        });
                        setPinLoc(PINmap?.long_name);
                        setLatLng({
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });

                        setAddressAuto(place.formatted_address);
                        setLatitude(place.geometry.location.lat());
                        setLongitude(place.geometry.location.lng());
                        formik.setFieldValue(
                          "address",
                          place.formatted_address
                        );
                      }}
                      style={{
                        padding: "20px",
                        borderRadius: "8px",
                        outline: "none",
                        border: `1px solid ${
                          formik.touched.address && formik.errors.address
                            ? "red"
                            : "#DDDDDD"
                        }`,
                        width: "100%",
                      }}
                      invalid={
                        formik.touched.address && formik.errors.address
                          ? true
                          : false
                      }
                    />
                    <SearchIcon
                      sx={{
                        left: "15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        height: "100%",
                        fontSize: "28px",
                        color: "#737373",
                        position: "absolute",
                      }}
                    />
                  </Box>
                  {formik.touched.address && formik.errors.address ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      Location field is required
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={400} />
              ) : (
                <MapComponent
                  isMultipleMarker={true}
                  markerItem={mapAllLocation}
                  mapCenter={latlng}
                  onChange={(newValue) => {
                    setLatLng({
                      lat: newValue.lat,
                      lng: newValue.lng,
                    });
                    getAddress(newValue.lat, newValue.lng);
                    setLatitude(newValue.lat);
                    setLongitude(newValue.lng);
                  }}
                  getAddress={getAddress}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <PhoneInput
                    name="phoneNumber "
                    country={"in"}
                    placeholder="Mobile Number"
                    value={formik.values.phno_cc + formik.values.phoneNumber}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phno_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phoneNumber", phno);
                      formik.setFieldValue("phno_cc", phno_cc);
                    }}
                    inputClass="inputClass"
                    style={{
                      border: `1px solid ${
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      borderRadius: "8px",
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.phoneNumber}
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.email && formik.errors.email
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  autoComplete="new-Email"
                  invalid={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  onKeyUp={formik.handleBlur}
                  readOnly={typeCheck === "edit" ? true : false}
                />
              )}
              {formik.touched.email && formik.errors.email ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Grid>
            {typeCheck !== "edit" && (
              <Grid item xs={12}>
                {isLoading || busy ? (
                  <Skeleton height={48} />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        padding: "20px 50px 20px 20px",
                        borderRadius: "8px",
                        outline: "none",
                        border: `1px solid ${
                          formik.touched.password && formik.errors.password
                            ? "red"
                            : "#DDDDDD"
                        }`,
                        width: "100%",
                      }}
                      onKeyUp={formik.handleBlur}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "10px",
                        top: "11px",
                      }}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {formik.touched.password && formik.errors.password ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        mt={1}
                        color={"red"}
                      >
                        {formik.errors.password}
                      </Typography>
                    ) : null}
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "14px 0px 14px 0px",
                  fontFamily: "Poppins",
                }}
              >
                Legal Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="businessRegistrationNumber"
                  placeholder="Business Registration Number"
                  value={formik.values.businessRegistrationNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.businessRegistrationNumber &&
                      formik.errors.businessRegistrationNumber
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  invalid={
                    formik.touched.businessRegistrationNumber &&
                    formik.errors.businessRegistrationNumber
                      ? true
                      : false
                  }
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.businessRegistrationNumber &&
              formik.errors.businessRegistrationNumber ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.businessRegistrationNumber}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="taxidentificationNumber"
                  placeholder="Taxidentification Number"
                  value={formik.values.taxidentificationNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.taxidentificationNumber &&
                      formik.errors.taxidentificationNumber
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  invalid={
                    formik.touched.taxidentificationNumber &&
                    formik.errors.taxidentificationNumber
                      ? true
                      : false
                  }
                  onKeyUp={formik.handleBlur}
                />
              )}
              {formik.touched.taxidentificationNumber &&
              formik.errors.taxidentificationNumber ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.taxidentificationNumber}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    background: "#F4F4F4",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border:
                      formik.touched.idCard && formik.errors.idCard
                        ? "1px solid red"
                        : null,
                  }}
                >
                  {!idCardName && (
                    <label htmlFor="id-card">
                      <Button
                        component="span"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          outline: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          textTransform: "capitalize",
                        }}
                        ref={uploadIdCardCoverButtonRef}
                        onKeyDown={handleIdCardTabKeyPress}
                        type="button"
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            margin: "8px 0px !important",
                          }}
                        >
                          Upload Document
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          {`(PSD,DOC,PDF)`}
                        </Typography>
                        <input
                          accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                          style={{ display: "none" }}
                          id="id-card"
                          type="file"
                          onChange={handleIdCardOnChange}
                        />
                      </Button>
                    </label>
                  )}
                  {idCardName && (
                    <Box
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={UPLOAD_GALLERY_ICON}
                        alt="Upload Icon"
                        effect="blur"
                        width={"42px"}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#000000",
                          padding: "5px 0px",
                        }}
                      >
                        {idCardName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#9C9C9C",
                        }}
                      >
                        Successfully Uploaded
                      </Typography>
                      <label htmlFor="id-card">
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                        <input
                          accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                          style={{ display: "none" }}
                          id="id-card"
                          type="file"
                          onChange={handleIdCardOnChange}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "50px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIdCard(null);
                            setIdCardName(null);
                            formik.setFieldValue("idCard", null);
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#46ADF2",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                      </label>
                    </Box>
                  )}
                </Box>
              )}
              {formik.touched.idCard && formik.errors.idCard ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  Id card field is required
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Fragment>
  );
};

export default RestaurantAdd;
