import React from "react";
import "./CheckBox.css";

const CheckBox = ({ onChange, checked, label }) => {
  return (
    <div className="checkBoxC">
      {label && <span className="label">{label}</span>}
      <label className="toggle-switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="switch" />
      </label>
    </div>
  );
};

export default CheckBox;
