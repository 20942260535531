import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ToastMessage from "./Components/ToastMessage/ToastMessage";
import routes from "./routes";
import "./App.css";
const App = () => {
  return (
    <Fragment>
      <Suspense fallback>
        <ToastMessage />
        <BrowserRouter>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                  exact={route.exact}
                  authenticate={route.authenticate}
                />
              );
            })}
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Fragment>
  );
};

export default App;
