import React from "react";
import { Button } from "@mui/material";
import CircularProgress from "../../Assets/Images/giphy.gif";
const CustomButton = (props) => {
  const {
    type,
    variant,
    fullWidth,
    onClick,
    btnTitle,
    children,
    disabled,
    loading,
  } = props;

  return (
    <Button
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled || loading}
      sx={{
        fontWeight: "700",
        fontSize: "18px",
        height: "56px",
        maxWidth: "261px",
        textTransform: "unset",
        backgroundColor: "#000000",
        "&:disabled": {
          backgroundColor: "#dddddd",
        },
        "&:hover": {
          backgroundColor: "#00E676",
          color: "#ffffff",
        },
      }}
      onClick={onClick}
      className={"customBtn"}
    >
      {loading ? (
        <>
          <img
            src={CircularProgress}
            alt="loader"
            width={"50px"}
            height={"50px"}
          />
        </>
      ) : (
        btnTitle
      )}
      {children}
    </Button>
  );
};

export default CustomButton;
