import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './CustomDropdown.css';

const CustomDropdown = (props) => {
    const { selectedValue, dropDownItems = [], placeholder, onSelect, defaultValue, disabled, moreValue, readOnly } = props;
    const [isOpen, setIsOpen] = useState(false);

    const getTitle = () => {
        if (selectedValue && selectedValue.id) {
            const item = dropDownItems.find(i => i.id === selectedValue.id || i.name === selectedValue);
            return (item && item.value) || placeholder;
        } else {
            return defaultValue || placeholder;
        }
    };

    return (
        <div className={selectedValue === '' ? "customDropdown colorChange" : 'customDropdown'}>
            <Dropdown
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                disabled={disabled}
            >
                <DropdownToggle caret>
                    {getTitle()}
                </DropdownToggle>
                <DropdownMenu>
                    {dropDownItems.map((item) => (
                        <DropdownItem
                            key={item.id}
                            onClick={() => {
                                onSelect(item);
                                setIsOpen(false); // Close the dropdown after selection
                            }}
                            disabled={disabled || readOnly}
                        >
                            <span>{item.value}</span>
                            {moreValue && <span>{item.secondValue}</span>}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

CustomDropdown.propTypes = {
    onClick: PropTypes.func
};

export default CustomDropdown;
