import { Box } from '@mui/material'
import React from 'react'
const PartnerSetting = () => {
  return (
    <Box sx={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'100vh'
    }}>Under Development</Box>
  )
}

export default PartnerSetting