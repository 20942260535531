import axios from "axios";
const AxiosInstance = axios.create({
  baseURL: 'https://api.yandi.app/api',
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const yandi = JSON.parse(localStorage.getItem("YandiWeb"));
    config.headers.token = yandi?.token;
    config.headers.apikey = yandi?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      console.error("Response Error:", error.response.status);
    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
