import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import LOGIN_BG from "../../../Assets/Images/loginBg.png";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import AxiosInstance from "../../../Config/AxiosInstance";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [busy, setBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const userCheck = JSON.parse(localStorage.getItem("YandiWeb"));
    if (userCheck) {
      if (userCheck?.role === 2) navigate("/admin/dashboard");
      if (userCheck?.role === 3) navigate("/partner/dashboard");
      if (userCheck?.role === 5) navigate("/restaurant/dashboard");
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email Address")
        .email("Please Provide A Valid Email Address"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(6, "Password must be at least 6 characters")
        .max(20, "Password must be at most 20 characters"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusy(true);
      if (busy) {
        return;
      } else {
      }
      try {
        const response = await AxiosInstance.post("/auth/login", {
          email: values.email,
          password: values.password,
        });
        if (response && response?.data.s === 1) {
          localStorage.setItem("YandiWeb", JSON.stringify(response?.data?.r));
          if (response?.data?.r?.role === Number(2)) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.data?.m,
            });
            navigate("/admin/dashboard", { replace: true });
          } else if (response?.data?.r?.role === Number(3)) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.data?.m,
            });
            navigate("/partner/dashboard", { replace: true });
          } else if (response?.data?.r?.role === Number(5)) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response?.data?.m,
            });
            const deviceId = uuidv4();
            const currentTime = new Date();
            const hours = currentTime.getHours();
            const minutes = currentTime.getMinutes();
            const seconds = currentTime.getSeconds();
            const milliseconds = currentTime.getMilliseconds();
            const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds}`;
            const deviceIdWithTime = deviceId + formattedTime;
            localStorage.setItem("deviceId", deviceIdWithTime);
            navigate("/restaurant/dashboard", { replace: true });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
              title: "error",
              text: "You Are Not Authenticated",
            });
          }
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "error",
            text: response?.data?.m,
          });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setBusy(false);
        resetForm();
      }
    },
  });

  return (
    <Grid container component="main" sx={{ height: "100%" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${LOGIN_BG})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              width: "646px",
              position: "relative",
              right: { sm: "30px", md: "60px", lg: "100px", xl: "150px" },
              borderRadius: "30px",
            }}
          >
            <Box sx={{ padding: "40px 60px" }}>
              {" "}
              <Typography level="h1" fontSize={42} fontWeight={700}>
                Welcome back!
              </Typography>
              <Typography fontSize={16} fontWeight={400}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit tortor eu
                dolorol egestas morbi sem vulputate etiam facilisis pellentesque
                ut quis.
              </Typography>
              <form
                sx={{
                  width: "100%",
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <Box sx={{ marginTop: "20px" }}>
                  <Typography fontSize={14} fontWeight={600} color="#000000">
                    Email
                  </Typography>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: `${
                            formik.touched.email && formik.errors.email
                              ? "1px solid red"
                              : "none"
                          }`, // Corrected line
                          boxShadow: "0px 4px 4px 0px #00000040",
                          height: "64px",
                        },
                      },
                      marginTop: "5px",
                    }}
                    type="text"
                    placeholder="example@email.com"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                    fullWidth
                    id="outlined-required"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ marginTop: "15px" }}>
                  <Typography fontSize={14} fontWeight={600} color="#000000">
                    Password
                  </Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: `${
                            formik.touched.password && formik.errors.password
                              ? "1px solid red"
                              : "none"
                          }`, // Corrected line
                          boxShadow: "0px 4px 4px 0px #00000040",
                          height: "64px",
                        },
                      },
                      marginTop: "5px",
                    }}
                    placeholder="Password"
                    fullWidth
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    invalid={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    size="small"
                    sx={{
                      color: "#000000",
                      fontWeight: "400",
                      marginTop: "10px",
                      fontSize: "18px",
                      fontFamily: "Inter",
                      textTransform: "unset",
                    }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </Button>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!(formik.isValid && formik.dirty)}
                    btnTitle="Log In"
                    loading={busy}
                  />
                </Box>
              </form>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
