import React, { useState, Fragment } from "react";
import { usePagination, useTable } from "react-table";
import Skeleton from "react-loading-skeleton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./CustomTable.css";
const ReactTable = ({
  columns,
  data,
  isLoading,
  maxHeight,
  handleNextPage,
  handlePrevPage,
  pageSize,
  disableNext,
  disablePrev,
  pagination,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageIndex },
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const totalItemCount = data.length;
  const currentPageItemCount = rows.length;

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
  };

  return (
    <Fragment>
      <div style={{ height: maxHeight, overflow: "auto" }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  {columns.map((_, colIndex) => (
                    <td key={colIndex}>
                      <Skeleton height={40} />
                    </td>
                  ))}
                </tr>
              ))
            ) : rows.length > 0 ? (
              page.map((row, rowIndex) => {
                prepareRow(row);
                const rowIndexWithinPage = pageIndex * pageSize + rowIndex + 1;
                return (
                  <tr {...row.getRowProps()} key={row.index}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} key={cell.column.id}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="paginationDiv">
          <>
            <button
              color=""
              onClick={handlePrevPage}
              className="prevArrowButton"
              disabled={disablePrev}
            >
              <KeyboardArrowLeftIcon />
            </button>
            <p>
              Showing {pageIndex * pageSize + 1} to{" "}
              {Math.min((pageIndex + 1) * pageSize, totalItemCount)} of{" "}
              {totalItemCount} entries
            </p>

            <div className="paginationCountInput">
              <input
                type="number"
                className="text-success"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
                disabled={true}
              />
            </div>
            <button
              onClick={handleNextPage}
              className="nextArrowButton"
              disabled={disableNext}
            >
              <KeyboardArrowRightIcon
                sx={{
                  color: "#ffffff",
                }}
              />
            </button>
          </>
        </div>
      )}
    </Fragment>
  );
};

export default ReactTable;
