import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import AxiosInstance from "../../../Config/AxiosInstance";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import Swal from "sweetalert2";
import CustomModal from "../../../Components/CustomModel/CustomModel";
import Skeleton from "react-loading-skeleton";
import { messaging } from "../../../Config/Firebase/Firebase";
import { getToken } from "firebase/messaging";
import { useSocket } from "../../../Config/Socket/SocketProvider";
import moment from "moment";
const RestaurauntDashboard = () => {
  const YANDIWEB = JSON.parse(localStorage.getItem("YandiWeb")) ?? null;
  const socket = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [availableMenu, setAvailableMenu] = useState([]);
  const [allOrderList, setAllOrderList] = useState([]);
  const [viewShowOrderModel, setShowViewOrderModel] = useState(false);
  const [viewOrderDetails, setViewOrderDetails] = useState({});
  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BARNOoasZO163xFobHXpf5YaCW8PnmnAP1G1BJQgj5LZFG067jcoQXqDoRrJdaq-wR5n4wWCT4yVFVk5sZyB6P8",
        });
        const deviceId = localStorage.getItem("deviceId");
        const formData = new FormData();
        formData.append("fcm_token", token);
        formData.append("device_id", deviceId);
        AxiosInstance.post(`/notification/fcm-add-update`, formData);
      } else if (permission === "denied") {
        alert("You denied for the notification");
      }
    };
    requestPermission();
  }, []);
  const getAllOrder = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        `/admin/restaurant/get-all-order?restaurant_id=${YANDIWEB?.id}&status=0&order_status=0`
      );
      if (response && response.data.s === 1) {
        const Rowdata = response?.data?.r.slice(0, 5);
        setAllOrderList(Rowdata);
      }
    } catch (error) {
      console.error("Error fetching Order list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllOrder();
  }, []);
  useEffect(() => {
    const getAvailableMenuList = async () => {
      setIsLoading(true);
      try {
        const response = await AxiosInstance.get(`/admin/restaurant/get-menu`);
        if (response && response.data && response.data.s === 1) {
          setAvailableMenu(response.data.r.slice(0, 10));
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getAvailableMenuList();
  }, []);
  useEffect(() => {
    if (socket) {
      const getNewOrderRequest = async (data) => {
        if (data.type === 6 && data.status === 9) {
          setShowViewOrderModel(true);
          await getAllOrder();
          try {
            const response = await AxiosInstance.get(
              `admin/restaurant/get-order-details?order_id=${data?.order_id}`
            );
            if (response && response?.data?.s === 1) {
              setViewOrderDetails(response?.data?.r);
            } else {
              console.error("Failed to fetch order details");
            }
          } catch (error) {
            console.error("Error fetching order details", error);
          }
        }
      };

      const cancelOrderRequestListener = async (data) => {
        if (data.type === 6) {
          if (data.status === 0) {
            setShowViewOrderModel(false);
            await getAllOrder();
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "Success",
              text: data.status_message,
            });
          }
        }
      };

      // Add event listeners
      socket.on("get-data", getNewOrderRequest);
      socket.on("get-data", cancelOrderRequestListener);

      return () => {
        // Remove event listeners
        socket.off("get-data", getNewOrderRequest);
        socket.off("get-data", cancelOrderRequestListener);
      };
    }
  }, [socket, getAllOrder]);
  const handleViewOrderRequest = async (id) => {
    setIsBusy(true);
    setShowViewOrderModel(true);
    try {
      const response = await AxiosInstance.get(
        `admin/restaurant/get-order-details?order_id=${id}`
      );
      if (response && response?.data?.s === Number(1)) {
        setViewOrderDetails(response?.data?.r);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleViewOrderRequestAcceptReject = async (
    orderId,
    acceptReject,
    orderStatus
  ) => {
    socket.emit("send-data", {
      type: 7,
      order_id: orderId,
      accept_reject: acceptReject,
    });
    const acceptRejectListener = async (data) => {
      if (data.type === 7) {
        if (data.status === 10 || data.status === 11) {
          await getAllOrder();
          setShowViewOrderModel(false);
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: data.status_message,
          });
          socket.off("get-data", acceptRejectListener);
        }
      }
    };
    socket.on("get-data", acceptRejectListener);
    if (orderStatus !== undefined) {
      setTimeout(() => {
        socket.emit("send-data", {
          type: 8,
          order_id: orderId,
          order_status: orderStatus,
        });
        const orderStatusListener = async (data) => {
          if (data.type === 8 && data.status === 10) {
            await getAllOrder();
            socket.off("get-data", orderStatusListener);
          }
        };

        socket.on("get-data", orderStatusListener);
      }, 3000);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "100px",
              width: "100px",
            }}
          >
            Order ID
          </Box>
        ),
        accessor: "id",
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "100px",
                width: "100px",
              }}
            >
              {row.row.index + 1}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Name
          </Box>
        ),
        accessor: "name",
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.user_details?.full_name}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Phone Number
          </Box>
        ),
        accessor: "phone_number",
        Cell: (row) => {
          const { phno_cc, phno } = row?.row?.original?.user_details || {};
          const phoneNumber = `${phno_cc} ${phno}`;
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {phoneNumber}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Item
          </Box>
        ),
        accessor: `item`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.item_count}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Status
          </Box>
        ),
        accessor: `status`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.order_status === 0 && (
                <Chip
                  label="Pending"
                  sx={{ backgroundColor: "#10A2E0", color: "#ffffff" }}
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            Action
          </Box>
        ),
        accessor: `action`,
        Cell: ({ value, row }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              <Button
                sx={{
                  background: "#10A2E0",
                  border: "1px solid #10A2E0",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "#10A2E0",
                    border: "1px solid #10A2E0",
                  },
                }}
                onClick={() => handleViewOrderRequest(row?.original?.id)}
              >
                View
              </Button>
              <Button
                sx={{
                  background: "rgba(226, 43, 43, 1)",
                  border: "1px solid rgba(226, 43, 43, 1)",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "rgba(226, 43, 43, 1)",
                    border: "1px solid rgba(226, 43, 43, 1)",
                  },
                }}
                onClick={() => handleViewOrderRequest(row?.original?.id)}
              >
                Reject
              </Button>
              <Button
                sx={{
                  background: "rgba(26, 156, 5, 1)",
                  border: "1px solid rgba(26, 156, 5, 1)",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "rgba(26, 156, 5, 1)",
                    border: "1px solid rgba(26, 156, 5, 1)",
                  },
                }}
                onClick={() => handleViewOrderRequest(row?.original?.id)}
              >
                Accept
              </Button>
            </Box>
          );
        },
      },
    ],
    []
  );
  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          padding: "15px 24px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "#161616",
            fontSize: "24px",
            fontFamily: "Poppins",
          }}
        >
          Dashboard
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px 0px 0px 0px",
            gridGap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              color: "#161616",
              fontSize: "20px",
              fontFamily: "Poppins",
            }}
          >
            Available Menu
          </Typography>
          <Button
            type="button"
            sx={{
              textTransform: "capitalize",
              fontWeight: "00",
              color: "#161616",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            View All
          </Button>
        </Box>
        <Box
          sx={{
            padding: "10px 0px 0px 0px",
          }}
        >
          <Grid container spacing={3}>
            {isLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                  xxl={3}
                  key={index}
                >
                  <Card
                    sx={{
                      backgroundColor: "#142328",
                      borderRadius: "15px",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Skeleton width={100} height={50} />
                        <Skeleton width={50} height={50} />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : availableMenu.length > 0 ? (
              availableMenu.map((elem, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                  xxl={3}
                  key={index}
                >
                  <Card
                    sx={{
                      backgroundColor: "#142328",
                      borderRadius: "15px",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#ffffff",
                            fontSize: "18px",
                            fontFamily: "Poppins",
                            textTransform:'capitalize'
                          }}
                        >
                          {elem?.cuisine}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            padding: "15px",
                            backgroundColor: "#ffffff",
                            borderRadius: "10px",
                            fontSize: "20px",
                            fontWeight: "400",
                            color: "#000000",
                          }}
                        >
                          GNF{elem?.price}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              // Show message if no menu available
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#252525",
                    fontFamily: "Poppins",
                    textAlign: "center",
                    width: "100%",
                    padding: "50px 0px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    backgroundColor: "#ffffff",
                  }}
                >
                  No menu available.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px 0px 0px 0px",
            gridGap: "10px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              color: "#161616",
              fontSize: "20px",
              fontFamily: "Poppins",
            }}
          >
            Requested Order
          </Typography>
          <Button
            type="button"
            sx={{
              textTransform: "capitalize",
              fontWeight: "00",
              color: "#161616",
              fontSize: "18px",
              fontFamily: "Poppins",
            }}
          >
            View All
          </Button>
        </Box>
        <Box
          sx={{
            padding: "30px 0px 15px 0px",
          }}
        >
          <ReactTable
            columns={columns}
            data={allOrderList}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <CustomModal
        show={viewShowOrderModel}
        onCloseClick={() => setShowViewOrderModel(false)}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Box
            sx={{
              margin: "0px 0px 25px 0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Order #{viewOrderDetails?.id}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#797b7e",
                margin: "5px 0px 0px 0px",
                fontFamily: "Poppins",
              }}
            >
              {moment(viewOrderDetails?.created_at).format("DD MMM YYYY, hh:mm A")}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {viewOrderDetails &&
              viewOrderDetails?.order_details?.map((elem, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e3e3e3",
                        paddingBottom: "18px",
                      }}
                    >
                      <Box sx={{}}>
                        <img
                          src={`https://api.yandi.app${elem?.menu?.cover_img}`}
                          alt="order"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "calc(100% - 100px)",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Box className="orderDesc">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#000",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {elem?.menu?.item_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {`GNF${elem?.menu?.price}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Qty: {elem?.qty}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Typography
                    sx={{
                      color: "#797B7E",
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    X2 Items
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gridGap: "10px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="button"
                      className="rejectBtn actionBtn"
                      sx={{
                        fontFamily: "Poppins",
                        background: "rgba(226, 43, 43, 1)",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#fff",
                        padding: "12px 24px",
                        "&:hover": {
                          background: "rgba(226, 43, 43, 1)",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        handleViewOrderRequestAcceptReject(
                          viewOrderDetails?.id,
                          -1,
                          -1
                        );
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      type="button"
                      className="acceptBtn actionBtn"
                      sx={{
                        fontFamily: "Poppins",
                        background: "rgba(19, 117, 4, 1)",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#fff",
                        padding: "12px 24px",
                        "&:hover": {
                          background: "rgba(19, 117, 4, 1)",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        handleViewOrderRequestAcceptReject(
                          viewOrderDetails?.id,
                          1,
                          1
                        );
                      }}
                    >
                      Accept
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
    </Fragment>
  );
};

export default RestaurauntDashboard;
