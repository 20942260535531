import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../../../Config/AxiosInstance";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import foodDefualtImage from "../../../../Assets/Images/restaurant.png";
import DOCUMENTPREVIEW from "../../../../Assets/Images/document.png";
import Swal from "sweetalert2";
import ReactTable from "../../../../Components/CustomTable/CustomTable";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import CustomSearchBox from "../../../../Components/CustomSearch/CustomSearch";
const RestaurantsManagementView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchMenu, setSearchMenu] = useState("");
  const debouncedSearch = useDebounce(searchMenu, 500);
  const [restuarantDetails, setRestuarantDetails] = useState({});
  const [showMenuType, setShowMenuType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cuisineList, setCuisineList] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [restaurantMenuList, setRestaurantMenuList] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState(null);
  const handleDocumentDownload = (documentUrl) => {
    if (documentUrl) {
      const downloadUrl = `https://api.yandi.app${documentUrl}`;
      window.open(downloadUrl, "_blank");
    }
  };
  const getRestuarantDetails = async (restuarantId) => {
    try {
      const response = await AxiosInstance.get(
        `user/get-by-id?user_id=${restuarantId}`
      );
      if (response.data.s === 1) {
        setRestuarantDetails(response.data.r);
      }
    } catch (error) {
      console.error("Error fetching restaurant details:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (location?.state?.restaurantId) {
      const restaurantId = location?.state?.restaurantId;
      setRestaurantId(restaurantId);
      getRestuarantDetails(restaurantId);
    }
  }, [location?.state?.restaurantId]);
  const getCuisineList = async () => {
    try {
      const response = await AxiosInstance.get("/admin/restaurant/get-cuisine");
      if (response?.data?.s === 1) {
        setCuisineList(response?.data?.r);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.data?.m,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getCuisineList();
  }, []);
  useEffect(() => {
    if (restaurantId) {
      getRestaurantMenuList();
    }
  }, [restaurantId, debouncedSearch, selectedCuisine]);

  const getRestaurantMenuList = async () => {
    try {
      let params = {
        restaurant_id: restaurantId,
        count: 0,
        search: debouncedSearch,
      };
      if (selectedCuisine !== null) {
        params.cuisine_id = selectedCuisine;
      }

      const response = await AxiosInstance.get("/admin/restaurant/get-menu", {
        params: params,
      });

      if (response && response?.data?.s === 1) {
        const slicedData = response?.data?.r.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
        setRestaurantMenuList(slicedData);
      }
    } catch (error) {
      console.error("Error fetching restaurant menu list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCuisineSelect = (cuisineId) => {
    setSelectedCuisine(cuisineId);
    setShowMenuType(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (d) => d.index,
      },
      {
        Header: "Photo",
        accessor: (d) => `https://api.yandi.app${d?.cover_img}`,
        Cell: (row) => {
          return (
            <div className="text textOverflow">
              <img
                srcSet={`https://api.yandi.app${row?.row?.original?.cover_img}`}
                alt="profilephoto"
                width={"45px"}
                height={"45px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          );
        },
      },
      {
        Header: "Cuisine Name",
        accessor: (d) => d?.cuisine,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
                wordBreak: "break-word",
              }}
            >
              {row?.original?.cuisine}
            </Typography>
          );
        },
      },
      {
        Header: "Item Name",
        accessor: (d) => d?.item_name,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
                wordBreak: "break-word",
              }}
            >
              {row?.original?.item_name}
            </Typography>
          );
        },
      },
      {
        Header: "Description",
        accessor: (d) => d?.description,
        Cell: ({ row }) => {
          return (
            <Box sx={{ width: "250px", margin: "0 auto" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      padding: "8px",
                      fontSize: "0.8rem",
                      "& .MuiTooltip-arrow": {
                        color: "#000",
                      },
                    },
                  },
                }}
                placement="top"
                arrow
                title={
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#ffffff",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      wordBreak: "break-word",
                    }}
                  >
                    {row?.original?.description}
                  </Typography>
                }
              >
                <Typography
                  noWrap
                  sx={{
                    m: "auto",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    wordBreak: "break-word",
                  }}
                >
                  {row?.original?.description}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        Header: "Price(Per Plate)",
        accessor: (d) => `${d?.price}`,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >
              {`GNF${row?.original?.price}`}
            </Typography>
          );
        },
      },
      {
        Header: "Quantity",
        accessor: (d) => d?.qty,
        Cell: ({ row }) => {
          return (
            <Typography
              variant="body2"
              sx={{
                color: "#000000",
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >
              {row?.original?.qty}
            </Typography>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "10px",
            }}
          >
            <IconButton
              type="button"
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/admin/restuarant/update-menu", {
                  state: {
                    data: row?.original,
                    type: "view",
                  },
                })
              }
            >
              <RemoveRedEyeIcon
                sx={{
                  fontSize: "24px",
                  color: "#0098F6",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              type="button"
              onClick={() =>
                navigate("/admin/restuarant/update-menu", {
                  state: {
                    data: row?.original,
                    type: "edit",
                  },
                })
              }
            >
              <EditIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <DeleteForeverIcon
                sx={{
                  fontSize: "24px",
                  color: "red",
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <TopHeader title="Restaurant View" onClick={() => navigate(-1)} />
      <Box
        sx={{
          padding: "80px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
            backgroundColor: "#ffffff",
            padding: "30px 30px 30px 30px",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
              {isLoading ? (
                <Skeleton height={"300px"} />
              ) : (
                <Box
                  sx={{
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <img
                    src={
                      restuarantDetails?.profile_img
                        ? `https://api.yandi.app${restuarantDetails?.profile_img}`
                        : foodDefualtImage
                    }
                    alt="food_plate"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "100%",
                      border: "1px solid #000",
                      padding: "10px",
                      objectFit:'cover'
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} xxl={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Box sx={{}}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        wordBreak: "break-word",
                      }}
                    >
                      Restaurant Details
                    </Typography>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Restuarant Name
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.restaurant_name
                            ? restuarantDetails?.restaurant_name
                            : "N/N"}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Address
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.user_address?.length > 0
                            ? restuarantDetails?.user_address[0]?.address
                            : "N/N"}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Phone number
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.phno
                            ? `${restuarantDetails?.phno_cc} ${restuarantDetails?.phno}`
                            : "N/N"}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Email
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.email
                            ? restuarantDetails?.email
                            : "N/N"}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                  <Box sx={{}}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                        wordBreak: "break-word",
                      }}
                    >
                      Legal Information
                    </Typography>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Business Registration Number
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.user_document?.length > 0
                            ? JSON.parse(restuarantDetails.user_document[0]?.doc_reference).businessRegistrationNumber
                            : "N/A"}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Tax Identification Number
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"20px"} />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingTop: "4px",
                            fontFamily: "Poppins",
                            wordBreak: "break-word",
                          }}
                        >
                          {restuarantDetails?.user_document?.length > 0
                            ? JSON.parse(restuarantDetails.user_document[0]?.doc_reference).taxidentificationNumber
                            : "N/A"}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        paddingTop: "10px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                          wordBreak: "break-word",
                        }}
                      >
                        Document
                      </Typography>
                      {isLoading ? (
                        <Skeleton height={"100px"} />
                      ) : (
                        <Button
                          type="button"
                          style={{
                            width: "105px",
                            marginTop: "4px",
                            outline: "none",
                            border: "none",
                            backgroundColor: "#000000",
                            color:'#ffffff',
                            textTransform:'capitalize',
                            fontFamily: "Poppins",
                          }}
                          onClick={() =>
                            handleDocumentDownload(restuarantDetails?.user_document[0]?.document)
                          }
                        >
                          {/* <img
                            src={DOCUMENTPREVIEW}
                            alt="documentPrev"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          /> */}
                          Download
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            padding: "40px 0px 10px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#000000",
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "Poppins",
              }}
            >
              Your Menu
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "10px",
                position: "relative",
              }}
            >
              <Button
                type="button"
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "600",
                  outline: "none",
                  border: "none",
                  backgroundColor: "transparent",
                  transition: "0.5s",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                }}
                onClick={() =>
                  navigate("/admin/restuarant/add-menu", {
                    state: {
                      restaurant_id: restaurantId,
                    },
                  })
                }
              >
                Add Menu
              </Button>
              <IconButton
                type="button"
                onClick={() => setShowMenuType(!showMenuType)}
                sx={{
                  color: "#000000",
                  fontSize: "20px",
                  fontWeight: "600",
                  outline: "none",
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                <FilterAltIcon
                  sx={{
                    color: "#000",
                    fontSize: "32px",
                  }}
                />
              </IconButton>
              {showMenuType && (
                <Box
                  sx={{
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                    borderRadius: "8px",
                    width: "200px",
                    position: "absolute",
                    top: "100%",
                    right: "0px",
                    transition: "0.5s",
                    backgroundColor: "#fff",
                    zIndex: "10",
                  }}
                >
                  <ul
                    style={{
                      listStyle: "none",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {cuisineList?.length > 0 ? (
                      cuisineList?.map((elem, index) => {
                        return (
                          <li key={index}>
                            <Button
                              key={elem.id}
                              variant={
                                selectedCuisine === elem.id
                                  ? "contained"
                                  : "outlined"
                              }
                              onClick={() => handleCuisineSelect(elem.id)}
                              sx={{
                                border: "none",
                                backgroundColor: "transparent",
                                outline: "none",
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "600",
                                padding: "15px 20px",
                                fontFamily: "Poppins",
                                wordBreak: "break-word",
                                width: "100%",
                                ":hover": {
                                  backgroundColor: "#000000",
                                  color: "#ffffff",
                                  border: "1px solid #000000",
                                  transition: "0.5s",
                                },
                              }}
                            >
                              {elem?.cuisine}
                            </Button>
                          </li>
                        );
                      })
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#000000",
                          fontSize: "16px",
                          fontWeight: "400",
                          padding: "20px 20px",
                          fontFamily: "Poppins",
                          textAlign: "center",
                        }}
                      >
                        No Cuisine Data
                      </Typography>
                    )}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ paddingY: "20px" }}>
            <CustomSearchBox
              placeholder="Search Menu"
              value={searchMenu}
              onChange={(e) => setSearchMenu(e.target.value)}
              onClear={() => setSearchMenu("")}
            />
          </Box>
          <ReactTable
            columns={columns}
            data={restaurantMenuList}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default RestaurantsManagementView;
