import React, { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomModel from "../../../Components/CustomModel/CustomModel";
import BrandLogo from "../../../Assets/Images/logo.png";
import Icon1 from "../../../Assets/Images/PartnerImages/dashboardIcon.png";
import Icon2 from "../../../Assets/Images/admin/userIcon.png";
import Icon3 from "../../../Assets/Images/admin/partnerIcon.png";
import Icon4 from "../../../Assets/Images/admin/drivericon.png";
import Icon5 from "../../../Assets/Images/admin/restaurentIcon.png";
import Icon6 from "../../../Assets/Images/admin/subIcon.png";
import Icon7 from "../../../Assets/Images/admin/priceIcon.png";
import Icon8 from "../../../Assets/Images/admin/notIcon.png";
import Icon9 from "../../../Assets/Images/admin/setIcon.png";
import ICON10 from "../../../Assets/Images/PartnerImages/LogoutIcon.png";
import "./Sidebar.css";
import Swal from "sweetalert2";
const Sidebar = () => {
  const navigate = useNavigate();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const navLinkRoutes = [
    {
      path: "/admin/dashboard",
      icon: Icon1,
      activeIcon: Icon1,
      title: "Dashboard",
    },
    {
      path: "/admin/users",
      icon: Icon2,
      activeIcon: Icon2,
      title: "Users",
    },
    {
      path: "/admin/partner",
      icon: Icon3,
      activeIcon: Icon3,
      title: "Partner",
    },
    {
      path: "/admin/driver",
      icon: Icon4,
      activeIcon: Icon4,
      title: "Drivers",
    },
    {
      path: "/admin/restaurant",
      icon: Icon5,
      activeIcon: Icon5,
      title: "Restaurant",
    },
    {
      path: "/admin/sub-admin",
      icon: Icon6,
      activeIcon: Icon6,
      title: "Sub Admin",
    },
    {
      path: "/admin/price-management",
      icon: Icon7,
      activeIcon: Icon7,
      title: "Price Management",
    },
    {
      path: "/admin/notification",
      icon: Icon8,
      activeIcon: Icon8,
      title: "Notification",
    },
    {
      path: "/admin/setting",
      icon: Icon9,
      activeIcon: Icon9,
      title: "Settings",
    },
  ];
  const handleLogout = () => {
    setOpenConfirmModel(true);
    Swal.fire({
      title: "Are you sure?",
      text: "Logging out will end your session and you will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        Swal.fire({
          position: "center",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Logged Out",
          text: "You have been successfully logged out.",
        });
        localStorage.clear();
        navigate("/login");
      }
    });
  };
  return (
    <Fragment>
      <div className={"left-sidebar"}>
        <div className="sidebarContainer">
          <div className="brand-logo d-flex align-items-center justify-content-center">
            <img src={BrandLogo} width="100px" alt="icon" />
          </div>
          <div className="navigationContent">
            <ul className="nav_Item">
              {navLinkRoutes.map((elem, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={elem.path}
                      className={(navClass) =>
                        navClass.isActive ? "submenuLink" : "submenuLink"
                      }
                      exact={elem.exact}
                    >
                      <div
                        className={
                          elem.path === window.location.pathname
                            ? "active iconLink"
                            : "iconLink"
                        }
                      >
                        <img src={elem.icon} className="icon" alt="" />
                        <span className="text">{elem.title}</span>
                      </div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <button
              type="button"
              style={{
                outline: "none",
                border: "none",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "20px 20px",
                backgroundColor: "transparent",
                position: "absolute",
                bottom: "0",
              }}
              onClick={() => handleLogout()}
            >
              <img
                src={ICON10}
                className="logoutIcon"
                alt="logout"
                width={"24px"}
              />
              <span
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  marginLeft: "10px",
                }}
              >
                Logout
              </span>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
