import { Box, Grid, IconButton, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/admin/gallery.png";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import Skeleton from "react-loading-skeleton";
import AxiosInstance from "../../../../Config/AxiosInstance";
import Swal from "sweetalert2";
const RestaurantsAddMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cuisineList, setCuisineList] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [editMenuId, setEditMenuId] = useState(null);
  const [typeCheck, setTypeCheck] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setCoverImageFile(file);
        setCoverImagePreview(URL.createObjectURL(file));
      } else {
        toast.error("Selected file exceeds 5 MB limit.");
      }
    }
  };
  const getCuisineList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get("/admin/restaurant/get-cuisine");
      if (response?.data?.s === 1) {
        setCuisineList(response?.data?.r);
        setIsLoading(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.data?.m,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCuisineList();
  }, []);
  useEffect(() => {
    if (location?.state?.restaurant_id) {
      setRestaurantId(location?.state?.restaurant_id);
    }
  }, [location?.state?.restaurant_id]);
  useEffect(() => {
    if (location?.state?.type) {
      setTypeCheck(location?.state?.type);
      if (
        (typeCheck === "view" || typeCheck === "edit") &&
        location?.state?.data
      ) {
        setEditMenuId(location?.state?.data?.id);
        formik.setValues({
          name: location?.state?.data?.item_name,
          description: location?.state?.data?.description,
          price: location?.state?.data?.price,
          quantity: location?.state?.data?.qty,
          cuisine_id: location?.state?.data?.cuisine_id,
        });
        if (location?.state?.data?.cover_img) {
          const imageUrl = `https://api.yandi.app${location?.state?.data?.cover_img}`;
          setCoverImagePreview(imageUrl);
          setCoverImageFile(location.state.data.cover_img);
        }
        if (location?.state?.data?.cuisine_id) {
          const cuisine_id = location?.state?.data?.cuisine_id;
          const fineCuisine = cuisineList?.find(
            (elem, index) => elem?.id === cuisine_id
          );
          setSelectedCuisine(fineCuisine);
        }
      }
    }
  }, [location?.state?.data, typeCheck]);
  useEffect(() => {
    if (typeCheck === "edit") {
      setHasChanged(false);
    }
  }, [typeCheck]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      price: "",
      quantity: "",
      cuisine_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Item Name Is Required"),
      description: Yup.string().required("Description is required"),
      price: Yup.string()
        .matches(/^(\d+(\.\d{1,2})?)?$/, "Invalid price format")
        .required("Price is required"),
      quantity: Yup.string()
        .matches(/^(\d+)?$/, "Invalid quantity format")
        .required("Quantity is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setHasChanged(true);
      setIsLoading(true);
      if (isLoading) return;
      try {
        let formData = new FormData();
        formData.append("cuisine_id", values.cuisine_id);
        formData.append("item_name", values.name);
        formData.append("cover_img", coverImageFile);
        formData.append("description", values.description);
        formData.append("price", values.price);
        formData.append("qty", values.quantity);
        let endpoint =
          typeCheck === "edit"
            ? "/admin/restaurant/update-menu"
            : "/admin/restaurant/add-menu";
        if (typeCheck === "edit") {
          formData.append("id", editMenuId);
        } else {
          formData.append("restaurant_id", restaurantId);
        }
        const response = await AxiosInstance.post(endpoint, formData);
        if (response?.data?.s === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: response?.data?.m,
          });
          navigate(-1);
          setIsLoading(false);
          resetForm();
          setCoverImagePreview(null);
          setCoverImageFile(null);
          setSelectedCuisine(null);
        } else {
          throw new Error(response?.data?.m || "Unknown error occurred");
        }
      } catch (error) {
        console.log("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "Error",
          text:
            error.message || "An error occurred while processing your request",
        });
        setIsLoading(false);
      }
    },
  });
  return (
    <Box>
      <TopHeader
        title={
          typeCheck === "edit"
            ? "Update Menu"
            : typeCheck === "view"
            ? "View Menu"
            : "Add Menu"
        }
        onClick={() => navigate(-1)}
        type="button"
        btnTitle="Submit"
        onSubmit={formik.handleSubmit}
        loading={isLoading}
        disabled={!(formik.dirty && formik.isValid && coverImagePreview)}
        showButton={typeCheck === "view" ? false : true}
      />
      <Box
        sx={{
          padding: "80px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#000000",
            fontSize: "20px",
            fontWeight: "600",
            paddingBottom: "20px",
          }}
        >
          {typeCheck === "edit"
            ? "Update Menu"
            : typeCheck === "view"
            ? "View Menu"
            : "Add Menu"}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={8} xl={8} xxl={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {isLoading ? (
                  <Skeleton height={62} />
                ) : (
                  <CustomDropdown
                    selectedValue={
                      selectedCuisine
                        ? selectedCuisine
                        : typeCheck === "edit"
                        ? { id: formik.values.cuisine_id }
                        : null
                    }
                    dropDownItems={cuisineList?.map((v, i) => ({
                      id: v?.id,
                      value: v?.cuisine,
                      label: v?.cuisine,
                      key: i,
                    }))}
                    placeholder={"Cuisine Name"}
                    onSelect={(evt) => {
                      setSelectedCuisine(evt);
                      formik.setFieldValue("cuisine_id", evt.id);
                    }}
                    disabled={typeCheck === "view" ? true : false}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {isLoading ? (
                  <Skeleton height={62} />
                ) : (
                  <input
                    name="name"
                    placeholder="Item Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      border: `1px solid ${
                        formik.touched.name && formik.errors.name
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      width: "100%",
                    }}
                    readOnly={typeCheck === "view" ? true : false}
                  />
                )}
                {formik.touched.name && formik.errors.name ? (
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    mt={1}
                    color={"red"}
                  >
                    {formik.errors.name}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                {isLoading ? (
                  <Skeleton height={150} />
                ) : (
                  <textarea
                    name="description"
                    placeholder="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      resize: "none",
                      minHeight: "127px",
                      border: `1px solid ${
                        formik.touched.description && formik.errors.description
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      width: "100%",
                    }}
                    readOnly={typeCheck === "view" ? true : false}
                  />
                )}

                {formik.touched.description && formik.errors.description ? (
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    mt={1}
                    color={"red"}
                  >
                    {formik.errors.description}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {isLoading ? (
                  <Skeleton height={62} />
                ) : (
                  // <input
                  //   name="price"
                  //   placeholder="Price"
                  //   value={formik.values.price}
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  //   onKeyUp={formik.handleBlur}
                  //   style={{
                  //     padding: "20px",
                  //     borderRadius: "8px",
                  //     outline: "none",
                  //     border: `1px solid ${
                  //       formik.touched.price && formik.errors.price
                  //         ? "red"
                  //         : "#DDDDDD"
                  //     }`,
                  //     width: "100%",
                  //   }}
                  //   readOnly={typeCheck === "view"}
                  //   type="number"
                  //   min="0"
                  //   inputMode="numeric" // Adding inputMode attribute
                  //   pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                  //   onKeyDown={(evt) =>
                  //     ["e", "E", "+", "-"].includes(evt.key) &&
                  //     evt.preventDefault()
                  //   }
                  //   invalid={formik.touched.price && formik.errors.price}
                  // />
                  <input
                    name="price"
                    placeholder="Price"
                    value={formik.values.price}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue("price", value.replace(/^0+/, ""));
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue("price", value.replace(/^0+/, ""));
                      }
                      formik.handleBlur(e);
                    }}
                    onKeyUp={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      border: `1px solid ${
                        formik.touched.price && formik.errors.price
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      width: "100%",
                    }}
                    readOnly={typeCheck === "view"}
                    type="number"
                    min="1" // Ensure price cannot be zero or less
                    inputMode="numeric" // Adding inputMode attribute
                    pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    invalid={formik.touched.price && formik.errors.price}
                  />
                )}

                {formik.touched.price && formik.errors.price ? (
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    mt={1}
                    color={"red"}
                  >
                    {formik.errors.price}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                {isLoading ? (
                  <Skeleton height={62} />
                ) : (
                  // <input
                  //   name="quantity"
                  //   placeholder="Quantity"
                  //   value={formik.values.quantity}
                  //   onChange={formik.handleChange}
                  //   onBlur={formik.handleBlur}
                  //   onKeyUp={formik.handleBlur}
                  //   style={{
                  //     padding: "20px",
                  //     borderRadius: "8px",
                  //     outline: "none",
                  //     border: `${
                  //       formik.touched.quantity && formik.errors.quantity
                  //         ? "1px solid red"
                  //         : "1px solid #DDDDDD"
                  //     }`,
                  //     width: "100%",
                  //   }}
                  //   readOnly={typeCheck === "view"}
                  //   type="number"
                  //   min="0"
                  //   inputMode="numeric" // Adding inputMode attribute
                  //   pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                  //   onKeyDown={(evt) =>
                  //     ["e", "E", "+", "-"].includes(evt.key) &&
                  //     evt.preventDefault()
                  //   }
                  //   invalid={formik.touched.quantity && formik.errors.quantity}
                  // />
                  <input
                    name="quantity"
                    placeholder="Quantity"
                    value={formik.values.quantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue(
                          "quantity",
                          value.replace(/^0+/, "")
                        );
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value !== "" && value.startsWith("0")) {
                        formik.setFieldValue(
                          "quantity",
                          value.replace(/^0+/, "")
                        );
                      }
                      formik.handleBlur(e);
                    }}
                    onKeyUp={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      border: `${
                        formik.touched.quantity && formik.errors.quantity
                          ? "1px solid red"
                          : "1px solid #DDDDDD"
                      }`,
                      width: "100%",
                    }}
                    readOnly={typeCheck === "view"}
                    type="number"
                    min="1"
                    inputMode="numeric" // Adding inputMode attribute
                    pattern="[0-9]*" // Adding pattern attribute to allow only numeric input
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    invalid={formik.touched.quantity && formik.errors.quantity}
                  />
                )}

                {formik.touched.quantity && formik.errors.quantity ? (
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    mt={1}
                    color={"red"}
                  >
                    {formik.errors.quantity}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
            {isLoading ? (
              <Skeleton height={300} />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  overflow: "hidden",
                  background: "#ffffff",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #dddddd",
                  borderRadius: "8px",
                }}
              >
                {!coverImagePreview && (
                  <label htmlFor="cover-upload">
                    <Button
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        textTransform: "capitalize",
                      }}
                    >
                      <img
                        src={UPLOAD_GALLERY_ICON}
                        alt="Upload Icon"
                        effect="blur"
                        width={"42px"}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#000000",
                          margin: "8px 0px !important",
                        }}
                      >
                        Upload Image
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#9C9C9C",
                        }}
                      >
                        {`(JPG,JPEG,PNG)`}
                      </Typography>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-upload"
                        type="file"
                        onChange={handleCoverImageChange}
                      />
                    </Button>
                  </label>
                )}
                {coverImagePreview && (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      src={coverImagePreview}
                      alt="Uploaded Image"
                      effect="blur"
                      height={"100%"}
                      width={"100%"}
                      style={{
                        objectFit: "cover",
                      }}
                    />
                    {typeCheck !== "view" && (
                      <label htmlFor="cover-upload">
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              color: "#142328",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageChange}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "16px",
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            transition: "0.5s",
                            position: "absolute",
                            right: "50px",
                            top: "5px",
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCoverImagePreview(null);
                            setCoverImageFile(null);
                          }}
                        >
                          <CloseIcon
                            sx={{
                              color: "#142328",
                              fontSize: "26px",
                            }}
                          />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RestaurantsAddMenu;
