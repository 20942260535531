import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, Chip, Typography, Grid } from "@mui/material";
import AddAction from "../../../Components/AddAction/AddAction";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import AxiosInstance from "../../../Config/AxiosInstance";
import CustomModal from "../../../Components/CustomModel/CustomModel";
import Swal from "sweetalert2";
import { useSocket } from "../../../Config/Socket/SocketProvider";
import moment from "moment";
const RestaurauntPrepareOrders = () => {
  const YANDIWEB = JSON.parse(localStorage.getItem("YandiWeb")) ?? null;
  const socket = useSocket();
  const [page, setPage] = useState(0);
  const pageSize = 30;
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [prepareOrders, setPrepareorder] = useState([]);
  const [prepareOrderModel, setPrepareOrderModel] = useState(false);
  const [prepareOrderDetails, setPrepareOrderDetails] = useState(false);
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };
  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  const getAllOrder = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        `/admin/restaurant/get-all-order?restaurant_id=${YANDIWEB?.id}&order_status=1`
      );
      if (response && response.data.s === 1) {
        const Rowdata = response?.data?.r;
        setPrepareorder(Rowdata);
      }
    } catch (error) {
      console.error("Error fetching Order list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleViewOrderPrepare = async (id) => {
    setIsBusy(true);
    setPrepareOrderModel(true);
    try {
      const response = await AxiosInstance.get(
        `admin/restaurant/get-order-details?order_id=${id}`
      );
      if (response && response?.data?.s === Number(1)) {
        setPrepareOrderDetails(response?.data?.r);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    getAllOrder();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "100px",
              width: "100px",
            }}
          >
            Order ID
          </Box>
        ),
        accessor: "id",
        Cell: (row) => {
          console.log("row", row);
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "100px",
                width: "100px",
              }}
            >
              {row.row.index + 1}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Name
          </Box>
        ),
        accessor: "name", // Update this line
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.user_details?.full_name}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Phone Number
          </Box>
        ),
        accessor: "phone_number",
        Cell: (row) => {
          const { phno_cc, phno } = row?.row?.original?.user_details || {};
          const phoneNumber = `${phno_cc} ${phno}`;
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {phoneNumber}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Item
          </Box>
        ),
        accessor: `item`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.item_count}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Status
          </Box>
        ),
        accessor: `status`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.status === 1 && (
                <Chip
                  label="Preparing"
                  sx={{ backgroundColor: "#10A2E0", color: "#ffffff" }}
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            Action
          </Box>
        ),
        accessor: `action`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              <Button
                sx={{
                  background: "#10A2E0",
                  border: "1px solid #10A2E0",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "#10A2E0",
                    border: "1px solid #10A2E0",
                  },
                }}
                onClick={() => handleViewOrderPrepare(row?.row?.original?.id)}
              >
                View
              </Button>
              <Button
                sx={{
                  background: "rgba(26, 156, 5, 1)",
                  border: "1px solid rgba(26, 156, 5, 1)",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "rgba(26, 156, 5, 1)",
                    border: "1px solid rgba(26, 156, 5, 1)",
                  },
                }}
                onClick={() => handleViewOrderPrepare(row?.row?.original?.id)}
              >
                Ready
              </Button>
            </Box>
          );
        },
      },
    ],
    []
  );
  const handleViewOrderAccept = (id, status, orderStatus) => {
    socket.emit("send-data", {
      type: 8,
      order_id: id,
      order_status: orderStatus,
    });
    const readyListener = async (data) => {
      if (data.type === 8) {
        if (data.status === 12) {
          await getAllOrder();
          setPrepareOrderModel(false);
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: data.status_message,
          });
          socket.off("get-data", readyListener);
        }
      }
    };
    socket.on("get-data", readyListener);
    if (orderStatus !== undefined) {
      setTimeout(() => {
        socket.emit("send-data", {
          type: 8,
          order_id: id,
          order_status: orderStatus,
        });
        const orderStatusListener = async (data) => {
          if (data.type === 12) {
            await getAllOrder();
            socket.off("get-data", orderStatusListener);
          }
        };

        socket.on("get-data", orderStatusListener);
      }, 3000);
    }
  };
  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction title={"Prepare Orders"} />
      <Box>
        <ReactTable
          columns={columns}
          data={prepareOrders}
          isLoading={isLoading}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={prepareOrders.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
        />
      </Box>
      <CustomModal
        show={prepareOrderModel}
        onCloseClick={() => setPrepareOrderModel(false)}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Box
            sx={{
              margin: "0px 0px 25px 0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Order #{prepareOrderDetails?.id}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#797b7e",
                margin: "5px 0px 0px 0px",
                fontFamily: "Poppins",
              }}
            >
             {moment(prepareOrderDetails?.created_at).format("DD MMM YYYY, hh:mm A")}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {prepareOrderDetails &&
              prepareOrderDetails?.order_details?.map((elem, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e3e3e3",
                        paddingBottom: "18px",
                      }}
                    >
                      <Box sx={{}}>
                        <img
                          src={`https://api.yandi.app${elem?.menu?.cover_img}`}
                          alt="order"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "calc(100% - 100px)",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Box className="orderDesc">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#000",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {elem?.menu?.item_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {`GNF${elem?.menu?.price}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Qty: {elem?.qty}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
                  <Typography
                    sx={{
                      color: "#797B7E",
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    X2 Items
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8} xl={8} xxl={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gridGap: "10px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="button"
                      className="acceptBtn actionBtn"
                      sx={{
                        fontFamily: "Poppins",
                        background: "rgba(19, 117, 4, 1)",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#fff",
                        padding: "12px 24px",
                        "&:hover": {
                          background: "rgba(19, 117, 4, 1)",
                          color: "#fff",
                        },
                      }}
                      onClick={() => {
                        handleViewOrderAccept(prepareOrderDetails?.id, 2, 2);
                      }}
                    >
                      Ready
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default RestaurauntPrepareOrders;
