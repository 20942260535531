import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const MapComponent = (props) => {
    const [center, setCenter] = useState(props.mapCenter || { lat: 28.6139, lng: 77.209 });
    const [markerPosition, setMarkerPosition] = useState(center);

    useEffect(() => {
        if (props.mapCenter) {
            setCenter(props.mapCenter);
            setMarkerPosition(props.mapCenter);
        }
    }, [props.mapCenter]);

    const onMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setMarkerPosition({ lat, lng });
        props.onChange({ lat, lng });
        props.getAddress(lat, lng);
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '500px' }}
                center={center}
                zoom={12}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: true,
                    fullscreenControl: false
                }}
            >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                />
            </GoogleMap>
        </div>
    );
};

export default MapComponent;
