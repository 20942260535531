import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import AxiosInstance from "../../../../Config/AxiosInstance";
const Cuisine = () => {
  const [busy, setBusy] = useState(false);
  const [cuisineList, setCuisineList] = useState([]);
  const [typeCheck, setTypeCheck] = useState("add");
  const [editCuisineId, setEditCuisineId] = useState(null);
  const handleEditCuisine = async (id) => {
    setTypeCheck("edit");
    setEditCuisineId(id);
    const editCuisine = cuisineList?.find((elem) => id === elem?.id);
    if (editCuisine) {
      formik.setValues({
        title: editCuisine?.cuisine,
      });
    }
  };
  const handleDeleteCuisine = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete Cuisine?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AxiosInstance.post(
            "/admin/restaurant/update-cuisine",
            { id: id, status: Number(0) }
          );
          if (response?.data?.s) {
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              title: "Deleted!",
              text: response?.data?.m,
            });
            getCuisineList();
          } else {
            Swal.fire({
              title: "Update!",
              text: response?.data?.m,
              icon: "error",
            });
          }
        } catch (error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };
  useEffect(() => {
    getCuisineList();
  }, []);
  const getCuisineList = async () => {
    setBusy(true);
    try {
      const response = await AxiosInstance.get("/admin/restaurant/get-cuisine");
      if (response?.data?.s === 1) {
        setCuisineList(response?.data?.r);
        setBusy(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: response?.data?.m,
        });
        setBusy(false);
      }
    } catch (error) {
      console.log("error", error);
      setBusy(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title Is Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (busy) return;
      setBusy(true);
      try {
        let response;
        if (typeCheck === "edit") {
          response = await AxiosInstance.post(
            "/admin/restaurant/update-cuisine",
            {
              cuisine: values.title,
              id: editCuisineId,
            }
          );
        } else {
          response = await AxiosInstance.post("/admin/restaurant/add-cuisine", {
            cuisine: values.title,
          });
        }
        const isSuccess = response?.data?.s === 1;
        Swal.fire({
          position: "center",
          icon: isSuccess ? "success" : "error",
          showConfirmButton: false,
          timer: 2000,
          title: isSuccess ? "Success" : "Error",
          text: response?.data?.m,
        });
        if (isSuccess) {
          getCuisineList();
          resetForm();
          setBusy(false);
          setTypeCheck("add");
        } else {
          resetForm();
          setBusy(false);
          setTypeCheck("add");
        }
      } catch (error) {
        console.error("Error:", error);
        resetForm();
        setBusy(false);
        setTypeCheck("add");
      }
    },
  });
  return (
    <Box
      sx={{
        padding: "0px 0px 0px 0px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <input
              name="title"
              placeholder="Cuisine Name"
              value={formik.values.title}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trimStart() !== value) {
                  e.target.value = value.trimStart();
                }
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              style={{
                padding: "14px 16px 14px 16px",
                borderRadius: "8px",
                outline: "none",
                border: `1px solid ${
                  formik.touched.title && formik.errors.title
                    ? "red"
                    : "#DDDDDD"
                }`,
                width: "100%",
              }}
              invalid={
                formik.touched.title && formik.errors.title ? true : false
              }
            />

            {formik.touched.title && formik.errors.title ? (
              <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                {formik.errors.title}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={!(formik.isValid && formik.dirty)}
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  height: "50px",
                  maxWidth: "160px",
                  margin: "0 auto",
                  textTransform: "unset",
                  fontFamily: "Inter",
                  background: "#00E676",
                  "&:hover": {
                    background: "#00E676",
                  },
                }}
                btnTitle={typeCheck === "edit" ? "Update" : "Add"}
                loading={busy}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box
        sx={{
          padding: "24px 0px 0px 0px",
        }}
      >
        <Grid container spacing={2}>
          {cuisineList?.map((elem, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  borderRadius: "6px",
                  padding: "20px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#000",
                    fontFamily: "Poppins",
                    paddingRight: "100px",
                    margin: "0px",
                  }}
                >
                  {elem?.cuisine}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gridGap: "5px",
                  }}
                >
                  <IconButton
                    className="editButton"
                    onClick={() => handleEditCuisine(elem?.id)}
                    sx={{
                      outline: "none",
                      border: "none",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <EditIcon
                      sx={{
                        fontSize: "18px",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    className="deleteButton"
                    onClick={() => handleDeleteCuisine(elem?.id)}
                    sx={{
                      outline: "none",
                      border: "none",
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <DeleteOutlineIcon
                      sx={{
                        fontSize: "18px",
                        color: "red",
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Cuisine;
