import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import Swal from "sweetalert2";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import AxiosInstance from "../../../../Config/AxiosInstance";
import DUMMAYUSER from "../../../../Assets/Images/avtarProfile.jpg";
import Skeleton from "react-loading-skeleton";
import Constant from "../../../../utils/constant";
import moment from "moment/moment";
const UserViewManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  console.log('userDetails', userDetails);
  const [userRideHistory, setUserRideHistory] = useState([]);
  const getUserDetails = async (userId) => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${userId}`
      );
      if (response && response?.data?.s === 1) {
        setUserDetails(response?.data?.r);
      } else {
        if (response && response?.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Unauthorized",
            text: "You are not authorized to view this page.",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/login");
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "Error",
            text: response?.data?.m || "Unknown error occurred.",
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user details:", error);
    }
  };
  const getUserRideHistory = async (userId) => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get("/ride/ride-history", {
        params: {
          type: 1,
          user_id: userId,
        },
      });
      if (response && response?.data?.s === 1) {
        setUserRideHistory(response?.data?.r);
        setIsLoading(false);
      } else {
        if (response && response?.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Unauthorized",
            text: "You are not authorized to view this page.",
            allowOutsideClick: false,
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/login");
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "Error",
            text: response?.data?.m || "Unknown error occurred.",
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching user details:", error);
    }
  };
  useEffect(() => {
    if (location?.state?.userId) {
      const userId = location?.state?.userId;
      getUserDetails(userId);
      getUserRideHistory(userId);
    }
  }, [location?.state?.userId]);
  return (
    <Fragment>
      <TopHeader title={"User Details"} onClick={() => navigate(-1)} />
      <Box
        sx={{
          padding: "100px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  backgroundColor: "#ffffff",
                  padding: "15px",
                }}
              >
                <CardActionArea>
                  {isLoading ? (
                    <Skeleton height={250} />
                  ) : (
                    <CardMedia
                      component="img"
                      height="250"
                      image={
                        userDetails?.profile_img
                          ? `${Constant?.BASE_URL}${userDetails?.profile_img}`
                          : DUMMAYUSER
                      }
                      alt="avtar"
                      width={"100%"}
                      sx={{
                        objectPosition: "top",
                      }}
                    />
                  )}
                  <CardContent
                    sx={{
                      borderTop: "1px solid #dddddd",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#252525",
                        fontFamily: "Poppins",
                      }}
                    >
                      Full Name
                    </Typography>
                    {isLoading ? (
                      <Skeleton height={30} />
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#252525",
                          fontFamily: "Poppins",
                        }}
                      >
                        {userDetails?.full_name
                          ? userDetails?.full_name
                          : "N/A"}
                      </Typography>
                    )}
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#252525",
                        paddingTop: "20px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Email Address
                    </Typography>
                    {isLoading ? (
                      <Skeleton height={30} />
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#252525",
                          fontFamily: "Poppins",
                        }}
                      >
                        {userDetails?.email ? userDetails?.email : "N/A"}
                      </Typography>
                    )}
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#252525",
                        paddingTop: "20px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Mobile Number
                    </Typography>
                    {isLoading ? (
                      <Skeleton height={30} />
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#252525",
                          fontFamily: "Poppins",
                        }}
                      >
                        {userDetails?.phno
                          ? `${userDetails?.phno_cc} ${userDetails?.phno}`
                          : "N/A"}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gridGap: "10px",
                        margin: "24px 0px 0px 0px",
                      }}
                    >
                      {isLoading ? (
                        <Skeleton height={50} width={50} borderRadius={"50%"} />
                      ) : (
                        <Tooltip title="Delete User" placement="top">
                          <IconButton
                            sx={{
                              backgroundcColor: "#ffffff",
                              boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                              border: "1px solid #ddd",
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: "24px",
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isLoading ? (
                        <Skeleton height={50} width={50} borderRadius={"50%"} />
                      ) : (
                        <Tooltip title="Block User" placement="top">
                          <IconButton
                            sx={{
                              backgroundcColor: "#ffffff",
                              boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                              border: "1px solid #ddd",
                            }}
                          >
                            <PersonIcon
                              sx={{
                                fontSize: "24px",
                                color: "#000000",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#252525",
                      fontFamily: "Poppins",
                    }}
                  >
                    Ride History
                  </Typography>
                </Grid>
                {isLoading ? (
                  <Grid item xs={12} sm={12} md={12}>
                    <Skeleton height={100} count={3} />
                  </Grid>
                ) : userRideHistory?.length > 0 ? (
                  userRideHistory?.map((elem, index) => {
                    return (
                      <Grid item xs={12} sm={12} md={12} key={index}>
                        <Card
                          sx={{
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            backgroundColor: "#ffffff",
                            padding: "15px",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  User Name
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.user_details
                                    ? elem?.user_details?.full_name
                                    : "N/N"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Ride Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {userDetails?.updated_at
                                    ? moment(userDetails?.updated_at).format('LLL')
                                    : "N?A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Driver ID
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.driver_details
                                    ? elem?.driver_details?.id
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Driver Name
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.driver_details
                                    ? elem?.driver_details?.full_name
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Driver Number
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.driver_details
                                    ? `${elem?.driver_details?.phno_cc} ${elem?.driver_details?.phno}`
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Vehicle Model
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {userDetails?.user_address?.length > 0
                                    ? userDetails?.user_address[0]?.address
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Plate Number
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {userDetails?.user_address?.length > 0
                                    ? userDetails?.user_address[0]?.address
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Price
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.price ? `GNF${elem?.price}` : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Ride Fare
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {userDetails?.user_address?.length > 0
                                    ? userDetails?.user_address[0]?.address
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Rated
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.rating_review
                                    ? elem?.rating_review
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Start Address
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.start_address
                                    ? elem?.start_address
                                    : "N/A"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={4} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    wordBreak: "break-word",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  End Address
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#000000",
                                    textAlign: "left",
                                    width: "100%",
                                    paddingTop: "4px",
                                    textTransform: "capitalize",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {elem?.end_address
                                    ? elem?.end_address
                                    : "N/A"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#252525",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        width: "100%",
                        padding: "50px 0px",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      No ride history available.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserViewManagement;
