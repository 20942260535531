import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "react-loading-skeleton";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/admin/gallery.png";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";
import AxiosInstance from "../../../../Config/AxiosInstance";
import moment from "moment";
import Constant from "../../../../utils/constant";
const DriversAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uploadCoverButtonRef = useRef(null);
  const uploadIdCardButtonRef = useRef(null);
  const uploadInsuranceButtonRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [typeCheck, setTypeCheck] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [idCardName, setIdCardName] = useState(null);
  const [insuranceFile, setInsuranceFile] = useState(null);
  const [insuranceFileName, setInsuranceFileName] = useState(null);
  const [startDate, setStartDate] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [editDriverId, setEditDriverId] = useState(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: "File size exceeds the limit of 5MB",
        });
      }
    }
  };
  const handleIdCardOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setIdCardName(file.name);
        setIdCard(file);
        formik.setFieldValue("idCard", file);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: "File size exceeds the limit of 5MB",
        });
      }
    }
  };
  const handleInsuranceOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setInsuranceFileName(file.name);
        setInsuranceFile(file);
        formik.setFieldValue("insurance", file);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          title: "error",
          text: "File size exceeds the limit of 5MB",
        });
      }
    }
  };
  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <Button
      sx={{
        width: "100%",
        padding: "12px",
        borderRadius: "8px",
        outline: "none",
        height: "60px",
        fontSize: "15px",
        fontWeight: "400",
        color: "#000000",
        fontFamily: "Poppins",
        textTransform: "capitalize",
        textAlign: "left",
        border:
          formik.touched.expiryDate && formik.errors.expiryDate
            ? "1px solid red"
            : "1px solid rgb(221, 221, 221)",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value ? (
        value
      ) : (
        <span style={{ color: "#97A2B0", fontSize: "16px", fontWeight: "400" }}>
          Expiry Date
        </span>
      )}
    </Button>
  ));
  const getChooseVehicleList = async () => {
    try {
      const response = await AxiosInstance.get(`/service/vehicle/get-all`);
      if (response && response?.data?.s === Number(1)) {
        const responseData = response?.data?.r;
        setVehicleList(responseData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getServiceList = async (id) => {
    try {
      const response = await AxiosInstance.get(`/service/get?vehicle_id=${id}`);
      if (response && response?.data?.s === Number(1)) {
        const responseData = response?.data?.r;
        setServiceList(responseData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // const toggleService = (serviceId) => {
  //   const isSelected = selectedServices.some((s) => s.id === String(serviceId));
  //   if (isSelected) {
  //     setSelectedServices(
  //       selectedServices.filter((s) => s.id !== String(serviceId))
  //     );
  //     formik.setValues({
  //       ...formik.values,
  //       selectedServices: formik.values.selectedServices.filter(
  //         (s) => s !== String(serviceId)
  //       ),
  //     });
  //   } else {
  //     setSelectedServices([...selectedServices, String(serviceId)]);
  //     formik.setValues({
  //       ...formik.values,
  //       selectedServices: [
  //         ...formik.values.selectedServices,
  //         String(serviceId),
  //       ],
  //     });
  //   }
  // };
  const toggleService = (serviceId) => {
    const isSelected = selectedServices.includes(String(serviceId));
  
    if (isSelected) {
      // Service is already selected, remove it
      setSelectedServices(selectedServices.filter((s) => s !== String(serviceId)));
      formik.setValues({
        ...formik.values,
        selectedServices: formik.values.selectedServices.filter((s) => s !== String(serviceId)),
      });
    } else {
      // Service is not selected, add it
      setSelectedServices([...selectedServices, String(serviceId)]);
      formik.setValues({
        ...formik.values,
        selectedServices: [...formik.values.selectedServices, String(serviceId)],
      });
    }
  };
  
  const handleSelectVehicle = (evt) => {
    setSelectedVehicle(evt);
    formik.setFieldValue("vehicle_id", evt.id);
    getServiceList(evt.id);
    setSelectedServices([]);
  };
  const getDriverDetails = async (driversId) => {
    setIsLoading(true);
    setBusy(true);
    try {
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${driversId}`
      );
      // console.log(response);
      if (response && response?.data?.s === 1) {
        const driverData = response.data.r;
        const address = driverData.user_address?.[0]?.address || "";
        const userDocument = driverData.user_document?.[0]?.document || "";
        const userDocument1 = driverData.user_document?.[1]?.document || "";
        const countryCode = driverData.phno_cc || "";
        const phoneNumber = driverData.phno || "";
        const updatedPhoneNumber = countryCode ? phoneNumber : phoneNumber;
        const licenseDetails = JSON.parse(
          driverData.user_vehicle?.vehicle_details || "{}"
        );
        const vehicleDetails = JSON.parse(
          driverData.user_vehicle?.vehicle_details || "{}"
        );
        formik.setValues({
          fullName: driverData.full_name || "",
          phoneNumber: driverData.phno,
          address: address,
          email: driverData.email || "",
          licence_no: licenseDetails.licence_no || "",
          country_state: licenseDetails.country_state || "",
          expiryDate: licenseDetails.expiry_date || "",
          modelNumber: licenseDetails.model_no || "",
          color: licenseDetails.color || "",
          plateNumber: licenseDetails.plate_no || "",
          vehicle_id: driverData.vehicle_id || "",
          phno_cc: driverData?.phno_cc.startsWith("+")
            ? driverData.phno_cc.slice(1)
            : driverData.phno_cc,
          expiryDate: vehicleDetails.expiry_date || "",
        });
        let expiryDate = null;
        if (vehicleDetails.expiry_date) {
          const [day, month, year] = vehicleDetails.expiry_date.split("/");
          expiryDate = new Date(year, month - 1, day);
          setStartDate(expiryDate);
        }
        if (driverData.vehicle_id) {
          const checkVehicleId = vehicleList?.find(
            (elem, index) => elem?.id == driverData.vehicle_id
          );
          setSelectedVehicle(checkVehicleId);
          getServiceList(driverData.vehicle_id);
        }
        if (driverData.service) {
          const selectedServiceIds = driverData.service.split(",");
          setSelectedServices(selectedServiceIds);
          formik.setFieldValue('selectedServices', selectedServiceIds)
        } else {
          setSelectedServices([]);
        }
        if (driverData?.profile_img) {
          setCoverImageUploadPreview(
            `https://api.yandi.app${driverData.profile_img}`
          );
        }
        if (userDocument) {
          const cleanDocument = userDocument.replace(
            "/uploads/user_documents",
            ""
          );
          setIdCardName(cleanDocument);
        }
        if (userDocument1) {
          const cleanDocument = userDocument1.replace(
            "/uploads/user_documents",
            ""
          );
          setInsuranceFileName(cleanDocument);
        }
      }
      setIsLoading(false);
      setBusy(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setBusy(false);
    }
  };
  useEffect(() => {
    if (location?.state?.driversId) {
      const driversType = location?.state?.type;
      const driverId = location?.state?.driversId;
      setTypeCheck(driversType);
      setEditDriverId(driverId);
      getDriverDetails(driverId);
    }
  }, [location?.state?.driversId]);
  useEffect(() => {
    getChooseVehicleList();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      phno_cc: "",
      phoneNumber: "",
      address: "",
      licence_no: "",
      country_state: "",
      vehicle_id: "",
      expiryDate: "",
      modelNumber: "",
      color: "",
      plateNumber: "",
      idCard: null,
      insurance: null,
      selectedServices: [],
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Driver Name is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      address: Yup.string().required("Address is required"),
      email: Yup.string()
        .matches(Constant.REGEX.EMAIL, "Please Enter Valid Email Address")
        .required("Email Address Is Required"),
      password:
        typeCheck === "edit"
          ? Yup.string()
          : Yup.string()
              .required("Password is required")
              .min(6, "Password must be at least 6 characters")
              .max(20, "Password must be at most 20 characters"),
      licence_no: Yup.string().required("Driving License Number is required"),
      country_state: Yup.string().required(
        "License Issue Country State is required"
      ),
      expiryDate: Yup.string().required("Expiry Date is required"),
      modelNumber: Yup.string().required("Model Number is required"),
      color: Yup.string().required("Color is required"),
      plateNumber: Yup.string().required("Plate Number is required"),
      vehicle_id: Yup.string().required("Vehicle ID is required"),
      idCard:
        typeCheck === "edit"
          ? Yup.mixed()
          : Yup.mixed().required("ID card is required"),
      insurance:
        typeCheck === "edit"
          ? Yup.mixed()
          : Yup.mixed().required("Insurance is required"),
      selectedServices: Yup.array()
        .min(1, "Please select at least one service")
        .required("Please select at least one service"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) return;
      if (typeCheck === "edit") {
        try {
          const formData1 = new FormData();
          formData1.append("user_id", editDriverId);
          formData1.append("full_name", values.fullName);
          formData1.append("phno", values.phoneNumber);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("email", values.email);
          formData1.append("vehicle_id", values.vehicle_id);
          const serviceIds = selectedServices?.map((service) => service);
          formData1.append("service", serviceIds);
          if (coverImageUploadFile) {
            formData1.append("profile_img", coverImageUploadFile);
          }
          const response1 = await AxiosInstance.post("/user/update", formData1);
          if (response1 && response1?.data?.s === 1) {
            if (response1?.data?.r?.id) {
              const formData2 = new FormData();
              formData2.append("id", editDriverId);
              formData2.append("type", 1);
              formData2.append("address", values.address);
              const response2 = await AxiosInstance.post(
                "/user/address/update",
                formData2
              );
              if (response2 && response2?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response2?.data?.m,
                });
              }
              const formData3 = new FormData();
              formData3.append("id", editDriverId);
              if (idCard) {
                formData3.append("document", idCard);
              }
              formData3.append("doc_reference", JSON.stringify({}));
              const response3 = await AxiosInstance.post(
                "/user/document/update",
                formData3
              );
              if (response3 && response3?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response3?.data?.m,
                });
              }
              const formData4 = new FormData();
              formData4.append("id", editDriverId);
              if (insuranceFile) {
                formData4.append("document", insuranceFile);
              }
              formData4.append("doc_reference", JSON.stringify({}));
              const response4 = await AxiosInstance.post(
                "/user/document/update",
                formData4
              );
              if (response4 && response4?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response4?.data?.m,
                });
              }
              const legalInfo = {
                vehicle_id: values.vehicle_id,
                model_no: values.modelNumber,
                color: values.color,
                plate_no: values.plateNumber,
                licence_no: values.licence_no,
                country_state: values.country_state,
                expiry_date: values.expiryDate,
              };
              const formData5 = new FormData();
              formData5.append("user_id", editDriverId);
              formData5.append("vehicle_details", JSON.stringify(legalInfo));
              const response5 = await AxiosInstance.post(
                "/user/vehicle/add-update",
                formData5
              );
              if (response5 && response5?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response5?.data?.m,
                });
              }
            }
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response1?.data?.m,
            });
            navigate("/partner/drivers");
            resetForm();
            setCoverImageUploadFile(null);
            setCoverImageUploadPreview(null);
            setIdCard(null);
            setIdCardName(null);
            setInsuranceFile(null);
            setInsuranceFileName(null);
            setSelectedVehicle(null);
            setSelectedServices([]);
            setIsLoading(false);
            setBusy(false);
          }
        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
          setBusy(false);
        }
      } else {
        try {
          const formData1 = new FormData();
          formData1.append("full_name", values.fullName);
          formData1.append("phno", values.phoneNumber);
          formData1.append("phno_cc", `+${values.phno_cc}`);
          formData1.append("email", values.email);
          formData1.append("password", values.password);
          formData1.append("vehicle_id", values.vehicle_id);
          const serviceIds = selectedServices.map((service) => service);
          formData1.append("service", serviceIds);
          if (coverImageUploadFile) {
            formData1.append("profile_img", coverImageUploadFile);
          }
          const response1 = await AxiosInstance.post(
            "/admin/partner/add-driver",
            formData1
          );
          if (response1 && response1?.data?.s === 1) {
            if (response1?.data?.r?.id) {
              const driverId = response1?.data?.r?.id;
              const formData2 = new FormData();
              formData2.append("user_id", driverId);
              formData2.append("type", 1);
              formData2.append("address", values.address);
              const response2 = await AxiosInstance.post(
                "/user/address/add",
                formData2
              );
              if (response2 && response2?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response2?.data?.m,
                });
              }
              const formData3 = new FormData();
              formData3.append("user_id", driverId);
              if (idCard) {
                formData3.append("document", idCard);
              }
              formData3.append("doc_reference", JSON.stringify({}));
              const response3 = await AxiosInstance.post(
                "/user/document/add",
                formData3
              );
              if (response3 && response3?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response3?.data?.m,
                });
              }
              const formData4 = new FormData();
              formData4.append("user_id", driverId);
              if (insuranceFile) {
                formData4.append("document", insuranceFile);
              }
              formData4.append("doc_reference", JSON.stringify({}));
              const response4 = await AxiosInstance.post(
                "/user/document/add",
                formData4
              );
              if (response4 && response4?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response4?.data?.m,
                });
              }
              const legalInfo = {
                vehicle_id: values.vehicle_id,
                model_no: values.modelNumber,
                color: values.color,
                plate_no: values.plateNumber,
                licence_no: values.licence_no,
                country_state: values.country_state,
                expiry_date: values.expiryDate,
              };
              const formData5 = new FormData();
              formData5.append("user_id", driverId);
              formData5.append("vehicle_details", JSON.stringify(legalInfo));
              const response5 = await AxiosInstance.post(
                "/user/vehicle/add-update",
                formData5
              );
              if (response5 && response5?.data?.s === 1) {
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
                  title: "error",
                  text: response5?.data?.m,
                });
              }
            }
            Swal.fire({
              position: "center",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              title: "success",
              text: response1?.data?.m,
            });
            navigate("/partner/drivers");
            resetForm();
            setCoverImageUploadFile(null);
            setCoverImageUploadPreview(null);
            setIdCard(null);
            setIdCardName(null);
            setInsuranceFile(null);
            setInsuranceFileName(null);
            setSelectedVehicle(null);
            setSelectedServices([]);
            setIsLoading(false);
            setBusy(false);
          }
        } catch (error) {
          console.log("error", error);
          setIsLoading(false);
          setBusy(false);
        }
      }
    },
  });
  const handleCoverTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("cover-upload");
      fileInput.click();
    }
  };
  const handleIdCardTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("idCardUpload");
      fileInput.click();
    }
  };
  const handleInsuranceTabKeyPress = (event) => {
    if (event.keyCode === 13) {
      const fileInput = document.getElementById("insurance");
      fileInput.click();
    }
  };
  return (
    <Fragment>
      <TopHeader
        title={typeCheck === "edit" ? "Update Driver" : "Add Driver"}
        onClick={() => navigate(-1)}
        showButton={true}
        btnTitle={"Submit"}
        onSubmit={formik.handleSubmit}
        loading={isLoading}
      />
      <Box
        sx={{
          padding: "80px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            textAlign: "left",
            padding: "14px 0px 14px 0px",
            fontFamily: "Poppins",
          }}
        >
          Driver Information
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    background: "#F4F4F4",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!coverImageUploadPreview && (
                    <label htmlFor="cover-upload">
                      <Tooltip title="Upload Profile" placement="top">
                        <Button
                          component="span"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            outline: "none",
                            border: "none",
                            backgroundColor: "transparent",
                            textTransform: "capitalize",
                          }}
                          ref={uploadCoverButtonRef}
                          onKeyDown={handleCoverTabKeyPress}
                          type="button"
                        >
                          <img
                            src={UPLOAD_GALLERY_ICON}
                            alt="Upload Icon"
                            effect="blur"
                            width={"42px"}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000000",
                              margin: "8px 0px !important",
                            }}
                          >
                            Upload Profile
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#9C9C9C",
                            }}
                          >
                            {`(JPG,JPEG,PNG)`}
                          </Typography>
                          <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="cover-upload"
                            type="file"
                            onChange={handleCoverImageOnChange}
                          />
                        </Button>
                      </Tooltip>
                    </label>
                  )}
                  {coverImageUploadPreview && (
                    <Box>
                      <img
                        src={coverImageUploadPreview}
                        alt="Uploaded Image"
                        effect="blur"
                        height={"150px"}
                        width={"150px"}
                      />
                      <label htmlFor="cover-upload">
                        <Tooltip title="Edit" placement="top">
                          <IconButton
                            component="span"
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "16px",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              transition: "0.5s",
                              position: "absolute",
                              right: "5px",
                              top: "5px",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              },
                            }}
                          >
                            <EditIcon
                              sx={{
                                color: "#000000",
                                fontSize: "26px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="cover-upload"
                          type="file"
                          onChange={handleCoverImageOnChange}
                        />
                        <Tooltip title="Remove" placement="top">
                          <IconButton
                            component="span"
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "16px",
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              transition: "0.5s",
                              position: "absolute",
                              right: "50px",
                              top: "5px",
                              "&:hover": {
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCoverImageUploadPreview(null);
                              setCoverImageUploadFile(null);
                            }}
                          >
                            <CloseIcon
                              sx={{
                                color: "#000000",
                                fontSize: "26px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formik.values.fullName}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.fullName && formik.errors.fullName
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.fullName && formik.errors.fullName
                      ? true
                      : false
                  }
                />
              )}
              {formik.touched.fullName && formik.errors.fullName ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.fullName}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={formik.values.email}
                  id="email"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  autoComplete="new-Email"
                  fdprocessedid="skp9ze"
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.email && formik.errors.email
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  readOnly={typeCheck === "edit" ? true : false}
                />
              )}
              {formik.touched.email && formik.errors.email ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Grid>
            {typeCheck !== "edit" && (
              <Grid item xs={12}>
                {isLoading || busy ? (
                  <Skeleton height={48} />
                ) : (
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    {/* <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      id='password'
                      value={formik.values.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      autoComplete="false"
                      style={{
                        padding: "20px 50px 20px 20px",
                        borderRadius: "8px",
                        outline: "none",
                        border: `1px solid ${
                          formik.touched.password && formik.errors.password
                            ? "red"
                            : "#DDDDDD"
                        }`,
                        width: "100%",
                      }}
                      onKeyUp={formik.handleBlur}
                    /> */}
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      id="password"
                      value={formik.values.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.trimStart() !== value) {
                          e.target.value = value.trimStart();
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      autoComplete="new-Password"
                      style={{
                        padding: "20px 50px 20px 20px",
                        borderRadius: "8px",
                        outline: "none",
                        border: `1px solid ${
                          formik.touched.password && formik.errors.password
                            ? "red"
                            : "#DDDDDD"
                        }`,
                        width: "100%",
                      }}
                      onKeyUp={formik.handleBlur}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "10px",
                        top: "11px",
                      }}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {formik.touched.password && formik.errors.password ? (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        mt={1}
                        color={"red"}
                      >
                        {formik.errors.password}
                      </Typography>
                    ) : null}
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <PhoneInput
                    name="phoneNumber "
                    country={"in"}
                    placeholder="Mobile Number"
                    value={formik.values.phno_cc + formik.values.phoneNumber}
                    onChange={(value, country) => {
                      const countryCodeLength = country.dialCode.length;
                      const phno_cc = value.slice(0, countryCodeLength);
                      const phno = value.slice(countryCodeLength);
                      formik.setFieldValue("phoneNumber", phno);
                      formik.setFieldValue("phno_cc", phno_cc);
                    }}
                    inputClass="inputClass"
                    style={{
                      border: `1px solid ${
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      borderRadius: "8px",
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "4px",
                        paddingLeft: "10px",
                      }}
                    >
                      {formik.errors.phoneNumber}
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <textarea
                  type="text"
                  className="customInput customTextarea"
                  placeholder="Address"
                  name="address"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  onKeyUp={formik.handleBlur}
                  autoComplete="off"
                  invalid={
                    formik.touched.address && formik.errors.address
                      ? true
                      : false
                  }
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    resize: "none",
                    border: `1px solid ${
                      formik.touched.address && formik.errors.address
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                />
              )}
              {formik.touched.address && formik.errors.address ? (
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "14px",
                    marginTop: "4px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.address}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "150px",
                      overflow: "hidden",
                      background: "#F4F4F4",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border:
                        formik.touched.idCard && formik.errors.idCard
                          ? "1px solid red"
                          : null,
                    }}
                  >
                    {!idCardName && (
                      <label htmlFor="idCardUpload">
                        <Tooltip title="Upload Id Card" placement="top">
                          <Button
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              textTransform: "capitalize",
                            }}
                            ref={uploadIdCardButtonRef}
                            onKeyDown={handleIdCardTabKeyPress}
                            type="button"
                          >
                            <img
                              src={UPLOAD_GALLERY_ICON}
                              alt="Upload Icon"
                              effect="blur"
                              width={"42px"}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#000000",
                                margin: "8px 0px !important",
                              }}
                            >
                              Upload ID Card
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#9C9C9C",
                              }}
                            >
                              {`(PSD,DOC,PDF)`}
                            </Typography>
                            <input
                              accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                              style={{ display: "none" }}
                              id="idCardUpload"
                              type="file"
                              onChange={handleIdCardOnChange}
                            />
                          </Button>
                        </Tooltip>
                      </label>
                    )}
                    {idCardName && (
                      <Box
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            padding: "5px 0px",
                          }}
                        >
                          {idCardName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          Successfully Uploaded
                        </Typography>
                        <label htmlFor="idCardUpload">
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                            >
                              <EditIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <input
                            accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                            style={{ display: "none" }}
                            id="idCardUpload"
                            type="file"
                            onChange={handleCoverImageOnChange}
                          />
                          <Tooltip title="Remove" placement="top">
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "50px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIdCard(null);
                                setIdCardName(null);
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </Box>
                    )}
                  </Box>
                  {formik.touched.idCard && formik.errors.idCard ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      Upload ID Card Field Is Required
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "14px 0px 14px 0px",
                  fontFamily: "Poppins",
                }}
              >
                License Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="licence_no"
                  placeholder="Driving License Number"
                  value={formik.values.licence_no}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.licence_no && formik.errors.licence_no
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.licence_no && formik.errors.licence_no
                      ? true
                      : false
                  }
                />
              )}
              {formik.touched.licence_no && formik.errors.licence_no ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.licence_no}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="country_state"
                  placeholder="License Issue Country/State"
                  value={formik.values.country_state}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.country_state &&
                      formik.errors.country_state
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.country_state && formik.errors.country_state
                      ? true
                      : false
                  }
                />
              )}
              {formik.touched.country_state && formik.errors.country_state ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.country_state}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={2}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          const formattedDate =
                            moment(date).format("DD/MM/YYYY");
                          setStartDate(date);
                          formik.setFieldValue("expiryDate", formattedDate);
                        }}
                        customInput={<CustomDatePicker />}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  </Grid>
                  {formik.touched.expiryDate && formik.errors.expiryDate ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      Expiry date Field is required
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "14px 0px 14px 0px",
                  fontFamily: "Poppins",
                }}
              >
                Vehicle Registeration Details
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                  textAlign: "left",
                  padding: "14px 0px 14px 0px",
                  fontFamily: "Poppins",
                }}
              >
                Choose Vehicle
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <>
                  <Box
                    sx={{
                      padding: "0px 0px",
                      border:
                        formik.touched.vehicle_id && formik.errors.vehicle_id
                          ? "1px solid red"
                          : null,
                      borderRadius: "8px",
                    }}
                  >
                    <CustomDropdown
                      selectedValue={
                        selectedVehicle
                          ? selectedVehicle
                          : typeCheck === "edit"
                          ? { id: formik.values.vehicle_id }
                          : null
                      }
                      dropDownItems={vehicleList?.map((v, i) => ({
                        id: v?.id,
                        value: v?.vehicle_type,
                        label: v?.vehicle_type,
                        key: i,
                      }))}
                      placeholder={"Choose vehicle"}
                      onSelect={(evt) => {
                        handleSelectVehicle(evt);
                      }}
                    />
                  </Box>
                  {formik.touched.vehicle_id && formik.errors.vehicle_id ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      Vehicle_id Field Is Required
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="modelNumber"
                  placeholder="Model Number"
                  value={formik.values.modelNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.modelNumber && formik.errors.modelNumber
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.modelNumber && formik.errors.modelNumber
                      ? true
                      : false
                  }
                />
              )}
              {formik.touched.modelNumber && formik.errors.modelNumber ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.modelNumber}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="color"
                  placeholder="Color"
                  value={formik.values.color}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.color && formik.errors.color
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.color && formik.errors.color ? true : false
                  }
                />
              )}
              {formik.touched.color && formik.errors.color ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.color}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <input
                  type="text"
                  name="plateNumber"
                  placeholder="Plate Number"
                  value={formik.values.plateNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid ${
                      formik.touched.plateNumber && formik.errors.plateNumber
                        ? "red"
                        : "#DDDDDD"
                    }`,
                    width: "100%",
                  }}
                  onKeyUp={formik.handleBlur}
                  invalid={
                    formik.touched.plateNumber && formik.errors.plateNumber
                      ? true
                      : false
                  }
                />
              )}
              {formik.touched.plateNumber && formik.errors.plateNumber ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.plateNumber}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {isLoading || busy ? (
                <Skeleton height={150} />
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "150px",
                      overflow: "hidden",
                      background: "#F4F4F4",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border:
                        formik.touched.insurance && formik.errors.insurance
                          ? "1px solid red"
                          : null,
                    }}
                  >
                    {!insuranceFileName && (
                      <label htmlFor="insurance">
                        <Tooltip title="Insurance" placement="top">
                          <Button
                            component="span"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              outline: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              textTransform: "capitalize",
                            }}
                            ref={uploadInsuranceButtonRef}
                            onKeyDown={handleInsuranceTabKeyPress}
                            type="button"
                          >
                            <img
                              src={UPLOAD_GALLERY_ICON}
                              alt="Upload Icon"
                              effect="blur"
                              width={"42px"}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#000000",
                                margin: "8px 0px !important",
                              }}
                            >
                              Upload Insurance
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#9C9C9C",
                              }}
                            >
                              {`(PSD,DOC,PDF)`}
                            </Typography>
                            <input
                              accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                              style={{ display: "none" }}
                              id="insurance"
                              type="file"
                              onChange={handleInsuranceOnChange}
                            />
                          </Button>
                        </Tooltip>
                      </label>
                    )}
                    {insuranceFileName && (
                      <Box
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={UPLOAD_GALLERY_ICON}
                          alt="Upload Icon"
                          effect="blur"
                          width={"42px"}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000000",
                            padding: "5px 0px",
                          }}
                        >
                          {insuranceFileName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#9C9C9C",
                          }}
                        >
                          Successfully Uploaded
                        </Typography>
                        <label htmlFor="insurance">
                          <Tooltip title="Edit" placement="top">
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                            >
                              <EditIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <input
                            accept=".pdf,.psd,.doc,.docx,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/vnd.adobe.photoshop"
                            style={{ display: "none" }}
                            id="insurance"
                            type="file"
                            onChange={handleInsuranceOnChange}
                          />
                          <Tooltip title="Remove" placement="top">
                            <IconButton
                              component="span"
                              sx={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "16px",
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                transition: "0.5s",
                                position: "absolute",
                                right: "50px",
                                top: "5px",
                                "&:hover": {
                                  backgroundColor: "#ffffff",
                                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setInsuranceFile(null);
                                setInsuranceFileName(null);
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: "26px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </label>
                      </Box>
                    )}
                  </Box>
                  {formik.touched.insurance && formik.errors.insurance ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      Insurance Field Is Required
                    </Typography>
                  ) : null}
                </>
              )}
            </Grid>
            {serviceList?.length > 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#000000",
                    textAlign: "left",
                    padding: "14px 0px 14px 0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Choose Service
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {serviceList?.length > 0 &&
                  serviceList?.map((service) => {
                    return (
                      <Grid item xs={12} md={4} key={service.id}>
                        <Button
                          type="button"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: selectedServices.includes(String(service.id))
                              ? "#ffffff"
                              : "#000000",
                            background: selectedServices.includes(
                              String(service.id)
                            )
                              ? "#000000"
                              : "#F4F4F4",
                            "&:hover": {
                              backgroundColor: "#000000",
                              color: "#ffffff",
                            },
                            width: "100%",
                            height: "75px",
                            textTransform: "capitalize",
                          }}
                          onClick={() => toggleService(service.id)}
                        >
                          {service.service_name}
                        </Button>
                      </Grid>
                    );
                  })}
                <Grid item xs={12} sx={{
                  paddingTop:'0px'
                }} >
                  {selectedVehicle && formik.touched.selectedServices &&
                    formik.errors.selectedServices && (
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        mt={1}
                        color={"red"}
                      >
                        {formik.errors.selectedServices}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Fragment>
  );
};

export default DriversAdd;
