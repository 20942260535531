import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton, Button } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CheckBox from "../../../../Components/CheckBox/CheckBox";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import UPLOAD_GALLERY_ICON from "../../../../Assets/Images/admin/gallery.png";
import AxiosInstance from "../../../../Config/AxiosInstance";
import Skeleton from "react-loading-skeleton";
const AddSubAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [busy, setBusy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [coverImageUploadPreview, setCoverImageUploadPreview] = useState(null);
  const [coverImageUploadFile, setCoverImageUploadFile] = useState(null);
  const [allAccount, setAllAccount] = useState([
    {
      label: "User",
      id: "1",
    },
    {
      label: "Partner",
      id: "2",
    },
    {
      label: "Driver",
      id: "3",
    },
    {
      label: "Restaurant",
      id: "4",
    },
    {
      label: "Price",
      id: "5",
    },
    {
      label: "Notification",
      id: "6",
    },
    {
      label: "Home page Links",
      id: "7",
    },
  ]);
  const [selectedRights, setSelectedRights] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editId, setEditId] = useState(null);
  const handleCoverImageOnChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const imageUrl = URL.createObjectURL(file);
        setCoverImageUploadFile(file);
        setCoverImageUploadPreview(imageUrl);
      } else {
        toast.error(`File size exceeds the limit of 5MB`);
      }
    }
  };
  const handleCheckBoxChange = (elem) => {
    const { label, id } = elem;
    if (selectedRights.some((right) => right.id === id)) {
      setSelectedRights(selectedRights.filter((right) => right.id !== id));
      formik.setFieldValue(
        "rights",
        formik.values.rights.filter((right) => right.id !== id)
      );
    } else {
      setSelectedRights([...selectedRights, { label, id }]);
      formik.setFieldValue("rights", [...formik.values.rights, { label, id }]);
    }
  };
  useEffect(() => {
    if (location?.state?.userId) {
      setTypeCheck(location?.state?.type);
      setEditId(location?.state?.userId);
      getSubAdminList(location?.state?.userId);
    }
  }, [location?.state?.userId]);
  const getSubAdminList = async (userId) => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${userId}`
      );
      if (response && response?.data?.r) {
        const userDetails = response?.data?.r;
        formik.setValues({
          fullName: userDetails?.full_name ? userDetails.full_name : "",
          email: userDetails?.email ? userDetails.email : "",
          phoneNumber: userDetails?.phno ? userDetails.phno : "",
          phno_cc: userDetails?.phno_cc ? userDetails.phno_cc : "",
          rights: userDetails?.rights
            ? userDetails.rights
                .split(",")
                .map((rightId) => {
                  const account = allAccount.find((acc) => acc.id === rightId);
                  return account
                    ? { label: account.label, id: account.id }
                    : null;
                })
                .filter(Boolean)
            : [],
        });
        if (userDetails.profile_img) {
          setCoverImageUploadPreview(
            `https://api.yandi.app${userDetails?.profile_img}`
          );
          setCoverImageUploadFile(userDetails?.profile_img);
        } else {
          setCoverImageUploadFile(null);
          setCoverImageUploadPreview(null);
        }
        const rightsString = userDetails?.rights;
        const rightsArray = rightsString.split(",").map((id) => parseInt(id));
        const selectedRights = allAccount.filter((account) =>
          rightsArray.includes(parseInt(account.id))
        );
        setSelectedRights(selectedRights);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      phoneNumber: "",
      phno_cc: "",
      rights: [],
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .required("Please Enter Your Email Address")
        .email("Please Provide A Valid Email Address"),
      ...(typeCheck !== "edit" && {
        password: Yup.string()
          .min(6, "Password must be at least 6 characters")
          .max(20, "Password must be at most 20 characters")
          .required("Password is required"),
      }),
      phoneNumber: Yup.string().required("Phone Number is required"),
      rights: Yup.array().min(1, "Please select at least one right"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBusy(true);
      if (busy) {
        return;
      } else {
        if (typeCheck === "edit") {
          try {
            const formData = new FormData();
            formData.append("user_id", editId);
            formData.append("full_name", values.fullName);
            formData.append("phno", values.phoneNumber);
            formData.append("phno_cc", values.phno_cc);
            formData.append("profile_img", coverImageUploadFile);
            const rightsIds = selectedRights.map((right) => right.id);
            const rightsString = rightsIds.join(",");
            formData.append("rights", rightsString);
            const response = await AxiosInstance.post("/user/update", formData);
            if (response?.data?.s === 1 && response) {
              toast.success(response?.data?.m);
              navigate("/admin/sub-admin");
            } else {
              toast.error(response?.data?.m);
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              toast.error("Session expired. Please log in again.");
            } else {
              console.log("error", error);
              toast.error("An error occurred. Please try again later.");
            }
          } finally {
            setBusy(false);
            resetForm();
            setSelectedRights([]);
          }
        } else {
          try {
            const formData = new FormData();
            formData.append("full_name", values.fullName);
            formData.append("phno", values.phoneNumber);
            formData.append("phno_cc", values.phno_cc);
            formData.append("email", values.email);
            formData.append("password", values.password);
            formData.append("profile_img", coverImageUploadFile);
            const rightsIds = selectedRights.map((right) => right.id);
            const rightsString = rightsIds.join(",");
            formData.append("rights", rightsString);
            const response = await AxiosInstance.post(
              "/admin/add-sub-admin",
              formData
            );
            if (response?.data?.s === 1 && response) {
              toast.success(response?.data?.m);
              navigate("/admin/sub-admin");
            } else {
              toast.error(response?.data?.m);
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              toast.error("Session expired. Please log in again.");
            } else {
              console.log("error", error);
              toast.error("An error occurred. Please try again later.");
            }
          } finally {
            setBusy(false);
            resetForm();
            setSelectedRights([]);
          }
        }
      }
    },
  });
  return (
    <Fragment>
      <TopHeader
        title={typeCheck === "edit" ? "Update Sub Admin" : "Add Sub Admin"}
        onClick={() => navigate(-1)}
        showButton={true}
        onSubmit={formik.handleSubmit}
        disabled={!(formik.isValid && formik.dirty)}
        type="submit"
        btnTitle={typeCheck === "edit" ? "Update" : "Submit"}
        loading={busy}
      />
      <Box
        sx={{
          maxWidth: { xs: "95%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
          padding: "130px 15px 20px 15px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isLoading || busy ? (
              <Skeleton height={150} />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "150px",
                  overflow: "hidden",
                  background: "#F4F4F4",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!coverImageUploadPreview && (
                  <label htmlFor="cover-upload">
                    <Button
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        textTransform: "capitalize",
                      }}
                    >
                      <LazyLoadImage
                        src={UPLOAD_GALLERY_ICON}
                        alt="Upload Icon"
                        effect="blur"
                        width={"42px"}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#000000",
                          margin: "8px 0px !important",
                        }}
                      >
                        Upload Profile
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#9C9C9C",
                        }}
                      >
                        {`(JPG,JPEG,PNG)`}
                      </Typography>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-upload"
                        type="file"
                        onChange={handleCoverImageOnChange}
                      />
                    </Button>
                  </label>
                )}
                {coverImageUploadPreview && (
                  <Box>
                    <LazyLoadImage
                      src={coverImageUploadPreview}
                      alt="Uploaded Image"
                      effect="blur"
                      height={"150px"}
                      width={"150px"}
                    />
                    <label htmlFor="cover-upload">
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <EditIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="cover-upload"
                        type="file"
                        onChange={handleCoverImageOnChange}
                      />
                      <IconButton
                        component="span"
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "16px",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          transition: "0.5s",
                          position: "absolute",
                          right: "50px",
                          top: "5px",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                          },
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCoverImageUploadPreview(null);
                          setCoverImageUploadFile(null);
                        }}
                      >
                        <CloseIcon
                          sx={{
                            color: "#46ADF2",
                            fontSize: "26px",
                          }}
                        />
                      </IconButton>
                    </label>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {isLoading || busy ? (
              <Skeleton height={48} />
            ) : (
              <input
                type="text"
                name="fullName"
                placeholder="Enter Full Name"
                value={formik.values.fullName}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  outline: "none",
                  border: `1px solid ${
                    formik.touched.fullName && formik.errors.fullName
                      ? "red"
                      : "#DDDDDD"
                  }`,
                  width: "100%",
                }}
              />
            )}
            {formik.touched.fullName && formik.errors.fullName ? (
              <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                {formik.errors.fullName}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {isLoading || busy ? (
              <Skeleton height={48} />
            ) : (
              <PhoneInput
                name="phoneNumber "
                country={"in"}
                placeholder="Enter Mobile Number"
                value={formik.values.phno_cc + formik.values.phoneNumber}
                onChange={(value, country) => {
                  const countryCodeLength = country.dialCode.length;
                  const phno_cc = value.slice(0, countryCodeLength);
                  const phno = value.slice(countryCodeLength);
                  formik.setFieldValue("phoneNumber", phno);
                  formik.setFieldValue("phno_cc", phno_cc);
                }}
                inputClass="inputClass"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {isLoading || busy ? (
              <Skeleton height={48} />
            ) : (
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                readOnly={typeCheck === "edit" ? true : false}
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  outline: "none",
                  border: `1px solid ${
                    formik.touched.email && formik.errors.email
                      ? "red"
                      : "#DDDDDD"
                  }`,
                  width: "100%",
                }}
              />
            )}
            {formik.touched.email && formik.errors.email ? (
              <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                {formik.errors.email}
              </Typography>
            ) : null}
          </Grid>
          {typeCheck !== "edit" && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {isLoading || busy ? (
                <Skeleton height={48} />
              ) : (
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      outline: "none",
                      border: `1px solid ${
                        formik.touched.password && formik.errors.password
                          ? "red"
                          : "#DDDDDD"
                      }`,
                      width: "100%",
                    }}
                  />
                  <IconButton
                    aria-label="show"
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{
                      position: "absolute",
                      right: "15px",
                      top: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      outline: "none",
                      border: "none",
                      color: "#97a2b0",
                      fontSize: "22px",
                    }}
                  >
                    {showPassword ? (
                      <VisibilityIcon sx={{ fontSize: "26px" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ fontSize: "26px" }} />
                    )}
                  </IconButton>
                  {formik.touched.password && formik.errors.password ? (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      mt={1}
                      color={"red"}
                    >
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid rgb(221, 221, 221)",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <Grid container spacing={3}>
                {allAccount &&
                  allAccount?.map((elem, index) => {
                    return (
                      <Grid item xs={12} md={6} key={index}>
                        {isLoading || busy ? (
                          <Skeleton height={48} />
                        ) : (
                          <CheckBox
                            label={elem.label}
                            onChange={() => handleCheckBoxChange(elem)}
                            checked={selectedRights.some(
                              (right) => right.id === elem.id
                            )}
                          />
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default AddSubAdmin;
