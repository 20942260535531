import { Navigate } from "react-router-dom";
import Login from "./Pages/Auth/Login/Login";
import ForgotPassword from "./Pages/Auth/ForgotPassword/ForgotPassword";
import Verification from './Pages/Auth/Verification/Verification';
import ChangePassword from './Pages/Auth/ChangePassword/ChangePassword';
import AdminLayout from "./Layout/AdminLayout/AdminLayout";
import PartnerLayout from "./Layout/PartnerLayout/PartnerLayout";
import RestaurauntLayout from './Layout/RestaurantLayout/RestaurantLayout';
// ========== AdminRoutes ==========
import AdminDashboard from "./Pages/Admin/AdminDashboard/AdminDashboard";
import UserManagement from "./Pages/Admin/Users/UserManagement/UserManagement";
import UserViewManagement from "./Pages/Admin/Users/UserViewManagement/UserViewManagement";
import AdminPartnerManagement from './Pages/Admin/Partner/PartnerManagement/PartnerManagement';
import PartnerManagementView from "./Pages/Admin/Partner/PartnerManagementView/PartnerManagementView";
import PartnerAddManagement from './Pages/Admin/Partner/PartnerAddManagement/PartnerAddManagement';
import AdminDriversManagement from "./Pages/Admin/Drivers/DriverManagement/DriverManagement";
import AdminDriversView from "./Pages/Admin/Drivers/DriversViewManagement/DriversViewManagement";
import RestaurantsManagement from "./Pages/Admin/Restaurants/RestaurantsManagement/RestaurantsManagement";
import RestaurantAdd from "./Pages/Admin/Restaurants/RestaurantAdd/RestaurantAdd";
import RestaurantsManagementView from "./Pages/Admin/Restaurants/RestaurantsManagementView/RestaurantsManagementView";
import RestaurantsAddMenu from "./Pages/Admin/Restaurants/RestaurantsAddMenu/RestaurantsAddMenu";
import SubAdmin from "./Pages/Admin/SubAdmin/SubAdmin";
import AddSubAdmin from "./Pages/Admin/SubAdmin/AddSubAdmin/AddSubAdmin";
import PriceManagement from "./Pages/Admin/PriceManagement/PriceManagement";
import Notification from './Pages/Admin/Notification/Notification';
import Setting from "./Pages/Admin/Setting/Setting";
// ========== PartnerRoutes ==========
import PartnerDashboard from "./Pages/Portal/PartnerDashboard/PartnerDashboard";
import DriversManagement from "./Pages/Portal/PartnerDrivers/DriversManagement/DriversManagement";
import DriversView from "./Pages/Portal/PartnerDrivers/DriversView/DriversView";
import DriversAdd from "./Pages/Portal/PartnerDrivers/DriversAdd/DriversAdd";
import PartnerPriceManagement from "./Pages/Portal/PartnerPriceManagement/PartnerPriceManagement";
import PartnerSetting from "./Pages/Portal/PartnerSetting/PartnerSetting";
// ========== RestaurauntRoutes ==========
import RestaurauntDashboard from "./Pages/Restauraunt/RestaurauntDashboard/RestaurauntDashboard";
import RestaurauntReceivedOrders from "./Pages/Restauraunt//RestaurauntReceivedOrders/RestaurauntReceivedOrders";
import RestaurauntPrepareOrders from "./Pages/Restauraunt//RestaurauntPrepareOrders/RestaurauntPrepareOrders";
import RestaurauntReadyfordelivery from "./Pages/Restauraunt//RestaurauntReadyfordelivery/RestaurauntReadyfordelivery";
import RestaurauntCompleted from "./Pages/Restauraunt//RestaurauntCompleted/RestaurauntCompleted";
const routes = [
    {
      path: "/",
      component: <Navigate to="/login" component={<Login />} />,
      exact: true,
    },
    {
      path: "/login",
      component: <Login />,
      exact: true,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />,
      exact: true,
    },
    {
      path: "/verification",
      component: <Verification />,
      exact: true,
    },
    {
      path: "/change-password",
      component: <ChangePassword />,
      exact: true,
    },
    {
      path: "/admin/*",
      component: <AdminLayout />,
      exact: true,
    },
    {
      path: "/partner/*",
      component: <PartnerLayout />,
      exact: true,
    },
    {
      path: "/restaurant/*",
      component: <RestaurauntLayout />,
      exact: true,
    },
];
export const adminLayoutRoute = [
  {
    path: "/dashboard",
    component: <AdminDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/users",
    component: <UserManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/users/view",
    component: <UserViewManagement />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/partner",
    component: <AdminPartnerManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/partner/add",
    component: <PartnerAddManagement />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/partner/view",
    component: <PartnerManagementView />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/partner/update",
    component: <PartnerAddManagement />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/driver",
    component: <AdminDriversManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/driver/view",
    component: <AdminDriversView />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restaurant",
    component: <RestaurantsManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/restaurant/add",
    component: <RestaurantAdd />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restaurant/update",
    component: <RestaurantAdd />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restuarant/view",
    component: <RestaurantsManagementView />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restuarant/add-menu",
    component: <RestaurantsAddMenu />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restuarant/update-menu",
    component: <RestaurantsAddMenu />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/restuarant/view-menu",
    component: <RestaurantsAddMenu />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/sub-admin",
    component: <SubAdmin />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/sub-admin/add",
    component: <AddSubAdmin />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/price-management",
    component: <PriceManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/notification",
    component: <Notification />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/setting",
    component: <Setting />,
    exact: true,
    sidebar: true,
  },
];
export const partnerLayoutRoute = [
  {
    path: "/dashboard",
    component: <PartnerDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/drivers",
    component: <DriversManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/drivers/view",
    component: <DriversView />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/drivers/add",
    component: <DriversAdd />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/drivers/edit",
    component: <DriversAdd />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/price-management",
    component: <PartnerPriceManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/setting",
    component: <PartnerSetting />,
    exact: true,
    sidebar: true,
  }
];
export const restaurauntLayoutRoute = [
  {
    path: "/dashboard",
    component: <RestaurauntDashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/received-orders",
    component: <RestaurauntReceivedOrders />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/prepare-orders",
    component: <RestaurauntPrepareOrders />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/ready-for-delivery",
    component: <RestaurauntReadyfordelivery />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/completed",
    component: <RestaurauntCompleted />,
    exact: true,
    sidebar: true,
  },
];
export default routes;