import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
const AddAction = (props) => {
  const location = useLocation();
  const { title, btnTitle, onClick, showButton } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 0px 10px 0px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#161616",
          fontSize: "26px",
          fontWeight: "700",
          margin: "0px",
          padding: "0px",
          fontFamily: 'Poppins'
        }}
      >
        {title}
      </Typography>

      {showButton && (
        <Button
          type="button"
          onClick={onClick}
          sx={{
            background: "#142328",
            fontSize: "16px",
            fontWeight: "600",
            color: "#ffffff",
            padding: "12px 20px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontFamily: 'Poppins',
            "&:hover": {
              backgroundColor: "#00E676",
              color: "#ffffff",
            }
          }}
        >
          {btnTitle}{" "}
          <AddIcon
            sx={{
              color: "#ffffff",
              fontSize: "22px",
              marginLeft: "5px",
            }}
          />
        </Button>
      )}
    </Box>
  );
};

export default AddAction;
