import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import AddAction from "../../../Components/AddAction/AddAction";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import AxiosInstance from "../../../Config/AxiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const Notification = () => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Notification title Is Required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (busy) return;
      setBusy(true);
      try {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('message', values.description);
        formData.append('type', '0');
        const response = await AxiosInstance.post("/notification/send", formData);
        if (response?.data?.s === 1 && response) {
          Swal.fire({
            position: "center",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            title: "Success",
            text: response?.data?.m,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            title: "Error",
            text: response?.data?.m,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Session Expired",
            text: "Your session has expired. Please log in again.",
            confirmButtonText: "OK",
          }).then(() => {
            localStorage.clear();
            localStorage.clear();
            navigate("/login");
          });
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      } finally{
        setBusy(false);
        resetForm()
      }
    },
    
  });

  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 15px",
      }}
    >
      <AddAction title={"Notification"} showButton={false} />
      <Box
        sx={{
          padding: "24px 0px 0px 0px",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <input
                name="title"
                placeholder="Notification Title"
                value={formik.values.title}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
              invalid={
                formik.touched.title && formik.errors.title ? true : false
              }
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  outline: "none",
                  border: `1px solid ${
                    formik.touched.title && formik.errors.title
                      ? "red"
                      : "#DDDDDD"
                  }`,
                  width: "100%",
                }}
              />

              {formik.touched.title && formik.errors.title ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.title}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <textarea
                name="description"
                placeholder="Description"
                value={formik.values.description}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                invalid={
                  formik.touched.description && formik.errors.description ? true : false
                }
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  outline: "none",
                  resize: "none",
                  minHeight: "127px",
                  border: `1px solid ${
                    formik.touched.description && formik.errors.description
                      ? "red"
                      : "#DDDDDD"
                  }`,
                  width: "100%",
                }}
              />

              {formik.touched.description && formik.errors.description ? (
                <Typography fontSize={14} fontWeight={500} mt={1} color={"red"}>
                  {formik.errors.description}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!(formik.isValid && formik.dirty)}
                  sx={{
                    fontWeight: "700",
                    fontSize: "18px",
                    height: "50px",
                    maxWidth: "160px",
                    margin: "0 auto",
                    textTransform: "unset",
                    fontFamily: "Inter",
                    background: "#00E676",
                    "&:hover": {
                      background: "#00E676",
                    },
                  }}
                  btnTitle="Send"
                  loading={busy}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Notification;
