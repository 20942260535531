import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CircularProgress from "../../Assets/Images/giphy.gif";
import { useLocation } from "react-router-dom";
const TopHeader = (props) => {
  const {
    type,
    variant,
    fullWidth,
    onSubmit,
    btnTitle,
    children,
    disabled,
    loading,
    showButton
  } = props;
  return (
    <Box
      sx={{
        backgroundColor: "#142328",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        width: "100%",
        padding: "0px 15px",
        zIndex: "10",
      }}
    >
      <Box
        sx={{
          width: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          onClick={props.onClick}
          sx={{
            width: "46px",
            height: "46px",
            borderRadius: "50%",
            backgroundColor: "#ffffff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            outline: "none",
            border: "none",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#ffffff",
            },
          }}
        >
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "32px",
              color: "#142328",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#ffffff",
            fontSize: "22px",
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "25%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {showButton && <Button
            type={type}
            variant={variant}
            fullWidth={fullWidth}
            disabled={disabled || loading}
            sx={{
              fontWeight: "700",
              fontSize: "18px",
              height: "56px",
              maxWidth: "200px",
              width:'100%',
              textTransform: "unset",
              backgroundColor: "#00E676 !important",
              color: "#ffffff !important",
              "&:disabled": {
                backgroundColor: "#dddddd !important",
                color: "#eee !important",
              },
              "&:hover": {
                backgroundColor: "#00E676 !important",
                color: "#ffffff !important",
              },
            }}
            onClick={onSubmit}
            className={"customBtn"}
          >
            {loading ? (
              <>
                <img
                  src={CircularProgress}
                  alt="loader"
                  width={"50px"}
                  height={"50px"}
                />
              </>
            ) : (
              btnTitle
            )}
            {children}
          </Button>
        }
      </Box>
    </Box>
  );
};

export default TopHeader;
