import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DOCUMENTICON from "../../../../Assets/Images/document.png";
import DUMMAYDRIVER from "../../../../Assets/Images/staticAvtar.png";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import AxiosInstance from "../../../../Config/AxiosInstance";
const AdminDriversView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [viewDriverDetails, setViewDriversDetails] = useState({});
  console.log('viewDriverDetails', viewDriverDetails);
  useEffect(() => {
    if (location?.state?.driversId) {
      const viewDriversId = location?.state?.driversId;
      getDriversDetails(viewDriversId);
    }
  }, [location?.state?.driversId]);
  const getDriversDetails = async (viewDriversId) => {
    try {
      const response = await AxiosInstance.get(
        `/user/get-by-id?user_id=${viewDriversId}`
      );
      if (response && response?.data?.r) {
        setViewDriversDetails(response?.data?.r);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box>
      <TopHeader title="Driver Details" onClick={() => navigate(-1)} />
      <Box
        sx={{
          padding: "100px 15px 40px 15px",
          maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
          margin: "0 auto",
          width: "100%",
        }}
      >
        {isLoading ? (
          <Grid container spacing={2}>
            {/* Skeleton for Card */}
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: "#142328" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Skeleton height={417} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card sx={{ backgroundColor: "#ffffff" }}>
                            <CardContent>
                              <Typography variant="h6" gutterBottom>
                                <Skeleton width={150} />
                              </Typography>
                              <Grid container spacing={3}>
                                {[1, 2, 3, 4].map((index) => (
                                  <Grid key={index} item xs={12} md={3}>
                                    <Skeleton height={30} />
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card
                sx={{
                  backgroundColor: "#142328",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Box
                        sx={{
                          height: "250px",
                        }}
                      >
                        <img
                          src={
                            viewDriverDetails?.profile_img
                              ? `https://api.yandi.app${viewDriverDetails?.profile_img}`
                              : DUMMAYDRIVER
                          }
                          alt="driversProfile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                            objectPosition: "top",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Driver Details
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={2}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Driver ID
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.id
                                      ? viewDriverDetails?.id
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Driver Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.full_name
                                      ? viewDriverDetails?.full_name
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {viewDriverDetails?.email
                                      ? viewDriverDetails?.email
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Phone Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.phno
                                      ? `${viewDriverDetails?.phno_cc} ${viewDriverDetails?.phno}`
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Address
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_address?.length > 0
                                      ? viewDriverDetails?.user_address[0]
                                          ?.address
                                      : "N?N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    ID Card
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_document?.length >
                                    0 ? (
                                      <Button
                                        target="_blank"
                                        download
                                        href={`https://api.yandi.app${viewDriverDetails?.user_document[0]?.document}`}
                                        sx={{ padding: "0px" }}
                                      >
                                        <img
                                          srcSet={DOCUMENTICON}
                                          alt="document"
                                          style={{ height: "40px" }}
                                        />
                                      </Button>
                                    ) : (
                                      "N?N"
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                License Details
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Driving License Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.licence_no
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Country/State
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                     {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.country_state
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Expiry Date
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.expiry_date
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Card
                            sx={{
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#000000",
                                  textAlign: "left",
                                  width: "100%",
                                  paddingBottom: "10px",
                                  fontFamily: "Poppins",
                                }}
                              >
                                Vehicle Registeration Details
                              </Typography>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Model Name
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.model_no
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Color
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.color
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Plate Number
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#000000",
                                      textAlign: "left",
                                      width: "100%",
                                      paddingTop: "4px",
                                      fontFamily: "Poppins",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {viewDriverDetails?.user_vehicle
                                      ? JSON.parse(
                                          viewDriverDetails?.user_vehicle
                                            ?.vehicle_details
                                        )?.plate_no
                                      : "N/N"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AdminDriversView;
