import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

const SOCKET_URL = "https://api.yandi.app/socket";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("YandiWeb"))?.id;
    const newSocket = io.connect(SOCKET_URL, {
      transports: ["websocket"],
      query: { user_id: userId },
    });
    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
// import React, { createContext, useContext, useEffect, useState } from "react";
// import io from "socket.io-client";

// const SOCKET_URL = "https://api.yandi.app/socket";

// const SocketContext = createContext();

// export const useSocket = () => useContext(SocketContext);

// export const SocketProvider = ({ children }) => {
//   const [socket, setSocket] = useState(null);

//   useEffect(() => {
//     const userId = JSON.parse(localStorage.getItem("YandiWeb"))?.id;
//     if (!userId) {
//       console.error("User ID not found in localStorage");
//       return;
//     }

//     const newSocket = io.connect(SOCKET_URL, {
//       transports: ["websocket"],
//       query: { user_id: userId },
//     });

//     newSocket.on("connect", () => {
//       console.log("WebSocket connected");
//     });

//     newSocket.on("disconnect", () => {
//       console.log("WebSocket disconnected");
//     });

//     newSocket.on("error", (error) => {
//       console.error("WebSocket error:", error);
//     });

//     setSocket(newSocket);

//     return () => {
//       if (newSocket) {
//         newSocket.disconnect();
//       }
//     };
//   }, []);

//   return (
//     <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
//   );
// };
