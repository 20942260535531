import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PersonIcon from "@mui/icons-material/Person";
import CustomTotalCard from "../../../Components/CustomTotalCard/CustomTotalCard";
import LineChart from "../../../Components/Chart/LineChart";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import AxiosInstance from "../../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [chartData, setChartData] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: (d) => d?.index,
      },
      {
        Header: "Partner Name",
        accessor: (d) => d?.full_name,
      },
      {
        Header: "Email",
        accessor: (d) => d?.email,
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno_cc} ${d?.phno}`,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "10px",
            }}
          >
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/admin/partner/view", {
                  state: {
                    userId: row?.original?.id,
                  },
                })
              }
            >
              <RemoveRedEyeIcon
                sx={{
                  fontSize: "24px",
                  color: "#0098F6",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <PersonIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getPartnerList();
    getAnalyticsDashboard();
    getChartDashboard();
  }, []);
  const getPartnerList = async () => {
    try {
      const storedItem = window.localStorage.getItem("YandiWeb");
      const sessionId = storedItem ? JSON.parse(storedItem).id : undefined;
      if (sessionId) {
        const response = await AxiosInstance.get("/user/get-all", {
          params: {
            role: 3,
            count: 0,
            add_by: sessionId,
          },
        });
        if (response && response?.data?.s === 1) {
          const slicedData = response?.data?.r
            .slice(0, 5)
            .map((elem, index) => ({
              ...elem,
              index: index + 1,
            }));
          setPartnerList(slicedData);
        }
      } else {
        console.error("Session ID is undefined");
      }
    } catch (error) {
      console.error("Error fetching drivers list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAnalyticsDashboard = async () => {
    try {
      const response = await AxiosInstance.get("/admin/analytics");
      if (response && response?.data?.s === 1) {
        setAnalyticsList(response?.data?.r);
      }
    } catch (error) {
      console.error("Error fetching drivers list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getChartDashboard = async () => {
    try {
      const response = await AxiosInstance.get("/admin/chart");
      if (response && response?.data?.s === 1) {
        const chartData = response?.data?.r;
        setChartData(chartData); // Set the chart data
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box sx={{ padding: "0px 15px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total Users"}
            count={analyticsList?.total_users}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total SubAdmin"}
            count={analyticsList?.total_subadmins}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total Partners"}
            count={analyticsList?.total_partners}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total Drivers"}
            count={analyticsList?.total_drivers}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total Restaurants"}
            count={analyticsList?.total_restaurants}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CustomTotalCard
            title={"Total Revenue"}
            count={`GNF ${analyticsList?.total_revenue}`}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Box
            sx={{
              height: { xs: "100%", md: "100%", lg: "100%", xl: "400px" },
              background: "#00E6760D",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                className="left"
                sx={{
                  paddingRight: "10px",
                  borderRight: "2px solid #000",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  {`GNF ${analyticsList?.total_revenue}`}
                </Typography>
                <Typography variant="body1">Total Earning</Typography>
              </Box>
              <Box
                sx={{
                  paddingLeft: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    backgroundColor: "#000",
                    padding: "6px 8px 6px 12px",
                    color: "#ffffff",
                    borderRadius: "8px",
                  }}
                >
                  Total number of Earning
                </Typography>
              </Box>
            </Box>
            <LineChart chartData={chartData} />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          paddingY: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 0px 10px 0px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#161616",
              fontSize: "20px",
              fontWeight: "700",
              margin: "0px",
              padding: "0px",
              textTransform: "capitalize",
            }}
          >
            Recent Partners
          </Typography>
          <Button
            onClick={() => navigate("/admin/partner")}
            type="button"
            sx={{
              color: "#000",
              fontSize: "18px",
              fontWeight: "500",
              textTransform: "capitalize",
            }}
          >
            View all
          </Button>
        </Box>
        <ReactTable
          columns={columns}
          data={partnerList}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default AdminDashboard;
