import React, { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomModel from '../../../Components/CustomModel/CustomModel';
import BrandLogo from "../../../Assets/Images/logo.png";
import ICON1 from "../../../Assets/Images/Restaurent/dashboard1.png";
import ICON2 from "../../../Assets/Images/Restaurent/orderRecive1.png";
import ICON3 from "../../../Assets/Images/Restaurent/prepare1.png";
import ICON4 from "../../../Assets/Images/Restaurent/ready1.png";
import ICON5 from "../../../Assets/Images/Restaurent/complete1.png";
import ICON6 from "../../../Assets/Images/PartnerImages/LogoutIcon.png";
import "./Sidebar.css";
const Sidebar = () => {
  const navigate = useNavigate();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const restaurauntNavlink = [
    {
      path: "/restaurant/dashboard",
      icon: ICON1,
      title: "Dashboard",
    },
    {
      path: "/restaurant/received-orders",
      icon: ICON2,
      title: "Received Orders",
    },
    {
      path: "/restaurant/prepare-orders",
      icon: ICON3,
      title: "Prepare Orders",
    },
    {
      path: "/restaurant/ready-for-delivery",
      icon: ICON4,
      title: "Ready for delivery",
    },
    {
      path: "/restaurant/completed",
      icon: ICON5,
      title: "Completed",
    },
  ];
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login')
  };
  return (
    <Fragment>
      <div className={"left-sidebar"}>
        <div className="sidebarContainer">
          <div className="brand-logo d-flex align-items-center justify-content-center">
            <img src={BrandLogo} width="100px" alt="icon" />
          </div>
          <div className="navigationContent">
            <div className="scrollNav">
              <ul className="nav_Item">
                {restaurauntNavlink.map((elem, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        to={elem.path}
                        className={(navClass) =>
                          navClass.isActive ? "submenuLink" : "submenuLink"
                        }
                        exact={elem.exact}
                      >
                        <div
                          className={
                            elem.path === window.location.pathname
                              ? "active iconLink"
                              : "iconLink"
                          }
                        >
                          <img src={elem.icon} className="icon" alt="" />
                          <span className="text">{elem.title}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="bottomDetails">
              <div className="action" onClick={() => setOpenConfirmModel(true)}>
                <img src={ICON6} className="logoutIcon" alt="logout" />
                <span>Log Out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModel
        show={openConfirmModel}
        onClose={() => setOpenConfirmModel(false)}
      >
        <div className="deleteModel">
          <h6>Are you sure you want to Logout?</h6>
          <div className="action">
            <button
              type="button"
              className="cBtn yes"
              onClick={() => handleLogout()}
            >
              Yes
            </button>
            <button
              type="button"
              className="cBtn no"
              onClick={() => setOpenConfirmModel(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModel>
    </Fragment>
  );
};

export default Sidebar;
