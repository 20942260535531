import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import AddAction from "../../../../Components/AddAction/AddAction";
import CustomSearchBox from "../../../../Components/CustomSearch/CustomSearch";
import ReactTable from "../../../../Components/CustomTable/CustomTable";
import AxiosInstance from "../../../../Config/AxiosInstance";
import useWindowDimension from "../../../../Components/hooks/useWindowDimension";
import useDebounce from "../../../../Components/useDebounce/useDebounce";

const AdminDriversManagement = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const [driversList, setDriversList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [driverSearch, setDriverSearch] = useState("");
  const debouncedSearch = useDebounce(driverSearch, 500);
  const [page, setPage] = useState(0);
  const pageSize = 30;
  const [count, setCount] = useState(0);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "rowid",
        Cell: (row) => row?.row?.original?.rowid,
      },
      {
        Header: "Driver Name",
        accessor: (d) => d.full_name,
      },
      {
        Header: "Email",
        accessor: (d) => d.email,
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno_cc}${d.phno}`,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "10px",
            }}
          >
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/admin/driver/view", {
                  state: {
                    driversId: row?.original?.id,
                  },
                })
              }
            >
              <RemoveRedEyeIcon
                sx={{
                  fontSize: "24px",
                  color: "#0098F6",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <PersonIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  const getDriversList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get("/user/get-all", {
        params: {
          role: 4,
          count: count,
          search: debouncedSearch,
        },
      });
      if (response && response.data.s === 1) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * 30,
          ...data,
        }));
        setDriversList(Rowdata);
      }
    } catch (error) {
      console.error("Error fetching drivers list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDriversList();
  }, [debouncedSearch, count]);
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };

  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction title={"Driver Management"} />
      <Box sx={{ paddingY: "20px" }}>
        <CustomSearchBox
          placeholder="Search Driver"
          value={driverSearch}
          onChange={(e) => setDriverSearch(e.target.value)}
          onClear={() => setDriverSearch("")}
        />
      </Box>
      <Box>
        <ReactTable
          columns={columns}
          data={driversList}
          isLoading={isLoading}
          maxHeight={dimensions.height - 230}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={driversList.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
        />
      </Box>
    </Box>
  );
};

export default AdminDriversManagement;
