import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAction from "../../../Components/AddAction/AddAction";
import CustomSearchBox from "../../../Components/CustomSearch/CustomSearch";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import useDebounce from "../../../Components/useDebounce/useDebounce";
import AxiosInstance from "../../../Config/AxiosInstance";
import useWindowDimension from "../../../Components/hooks/useWindowDimension";
import { useNavigate } from "react-router-dom";
const SubAdmin = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const [busy, setBusy] = useState(true);
  const [searchSubAdmin, setSearchSubAdmin] = useState("");
  const debouncedSearch = useDebounce(searchSubAdmin, 500);
  const [subAdminList, setSubAdminList] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 30;
  const [count, setCount] = useState(0);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "rowid",
        Cell: (row) => row?.row?.original?.rowid,
      },
      {
        Header: "Full Name",
        accessor: (d) => d?.full_name,
      },
      {
        Header: "Email",
        accessor: (d) => d?.email,
      },
      {
        Header: "Mobile Number",
        accessor: (d) => `${d?.phno_cc} ${d?.phno}`,
      },
      {
        Header: "No of Accesses",
        accessor: (d) => d.rights.split(",").length,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({row}) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "10px",
            }}
          >
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
              onClick={() =>
                navigate("/admin/sub-admin/add", {
                  state: {
                    userId: row?.original?.id,
                    type:'edit'
                  },
                })
              }
            >
              <EditIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <PersonIcon
                sx={{
                  fontSize: "24px",
                  color: "#000000",
                }}
              />
            </IconButton>
            <IconButton
              sx={{
                backgroundcColor: "#ffffff",
                boxsShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                border: "1px solid #ddd",
              }}
            >
              <DeleteIcon
                sx={{
                  fontSize: "24px",
                  color: "red",
                }}
              />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getPartnersList();
  }, [debouncedSearch, count]);
  const getPartnersList = async () => {
    try {
      const response = await AxiosInstance.get(`/user/get-all`, {
        params: {
          role: 6,
          count: count,
          search: debouncedSearch,
        },
      });
      if (response && response?.data?.s === Number(1)) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * 30,
          ...data,
        }));
        setSubAdminList(Rowdata);
      }
    } catch (error) {
    } finally {
      setBusy(false);
    }
  };
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };

  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 15px",
      }}
    >
      <AddAction
        title={"Sub Admin"}
        showButton={true}
        btnTitle={"Add"}
        onClick={() => navigate("/admin/sub-admin/add")}
      />
      <Box sx={{ paddingY: "20px" }}>
        <CustomSearchBox
          placeholder="Search Admin"
          value={searchSubAdmin}
          onChange={(e) => setSearchSubAdmin(e.target.value)}
          onClear={() => setSearchSubAdmin("")}
        />
      </Box>
      <Box>
        <ReactTable
          columns={columns}
          data={subAdminList}
          isLoading={busy}
          maxHeight={dimensions.height - 230}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={subAdminList.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
        />
      </Box>
    </Box>
  );
};

export default SubAdmin;
