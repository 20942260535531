import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, Chip, Typography, Grid } from "@mui/material";
import AxiosInstance from "../../../Config/AxiosInstance";
import CustomModal from "../../../Components/CustomModel/CustomModel";
import AddAction from "../../../Components/AddAction/AddAction";
import ReactTable from "../../../Components/CustomTable/CustomTable";
import Skeleton from "react-loading-skeleton";
const RestaurauntCompleted = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [completedList, setCompletedList] = useState([]);
  const [viewCompletedOrderModel, setViewCompletedOrderModel] = useState(false);
  const [viewCompletedOrder, setViewCompletedOrder] = useState({});
  const getCompletedOrderList = async () => {
    const YANDIWEB = JSON.parse(localStorage.getItem("YandiWeb")) ?? null;
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(
        "admin/restaurant/get-all-order",
        {
          params: {
            restaurant_id: YANDIWEB?.id,
            order_status: 5,
          },
        }
      );
      if (response && response.data.s === 1) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * 30,
          ...data,
        }));
        setCompletedList(Rowdata);
      }
    } catch (error) {
      console.error("Error fetching drivers list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCompletedOrderDetails = async (id) => {
    setViewCompletedOrderModel(true);
    setIsBusy(true);
    try {
      const response = await AxiosInstance.get(
        `admin/restaurant/get-order-details?order_id=${id}`
      );
      if (response && response?.data?.s === Number(1)) {
        setViewCompletedOrder(response?.data?.r);
        setIsBusy(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsBusy(false);
    }
  };
  const handleNextPage = () => {
    setCount((prevCount) => prevCount + pageSize);
    setPage((prevCount) => prevCount + 1);
  };
  const handlePrevPage = () => {
    if (count >= pageSize) {
      setCount((prevCount) => prevCount - pageSize);
      setPage((prevCount) => prevCount - 1);
    }
  };
  useEffect(() => {
    getCompletedOrderList();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "100px",
              width: "100px",
            }}
          >
            Order ID
          </Box>
        ),
        accessor: "id",
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "100px",
                width: "100px",
              }}
            >
              {row.row.index + 1}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Name
          </Box>
        ),
        accessor: "name", // Update this line
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.user_details?.full_name || "__"}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Phone Number
          </Box>
        ),
        accessor: "phone_number",
        Cell: (row) => {
          const { phno_cc, phno } = row?.row?.original?.user_details || {};
          const phoneNumber = `${phno_cc} ${phno}`;
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {phoneNumber}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Item
          </Box>
        ),
        accessor: `item`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.item_count}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Status
          </Box>
        ),
        accessor: `status`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                textAlign: "center",
                maxWidth: "200px",
                width: "200px",
              }}
            >
              {row?.row?.original?.order_status === 5 && (
                <Chip
                  label="Completed"
                  sx={{
                    backgroundColor: "rgba(26, 156, 5, 1)",
                    color: "#ffffff",
                  }}
                />
              )}
            </Box>
          );
        },
      },
      {
        Header: (props) => (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            Action
          </Box>
        ),
        accessor: `action`,
        Cell: (row) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gridGap: "10px",
              }}
            >
              <Button
                sx={{
                  background: "#10A2E0",
                  border: "1px solid #10A2E0",
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  fontFamily: "Poppins",
                  height: "45px",
                  padding: "12px 20px",
                  "&:hover": {
                    background: "#10A2E0",
                    border: "1px solid #10A2E0",
                  },
                }}
                onClick={() =>
                  handleCompletedOrderDetails(row?.row?.original?.id)
                }
              >
                View
              </Button>
            </Box>
          );
        },
      },
    ],
    []
  );
  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction title={"Completed"} />
      <Box sx={{ padding: "12px 0px" }}>
        <ReactTable
          columns={columns}
          data={completedList}
          isLoading={isLoading}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          pageSize={pageSize}
          disableNext={completedList.length < pageSize}
          disablePrev={count === 0}
          pagination={true}
        />
      </Box>
      <CustomModal
        show={viewCompletedOrderModel}
        onCloseClick={() => setViewCompletedOrderModel(false)}
      >
        <Box
          sx={{
            padding: "20px",
          }}
        >
          <Box
            sx={{
              margin: "0px 0px 25px 0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              Order #{viewCompletedOrder?.id}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#797b7e",
                margin: "5px 0px 0px 0px",
                fontFamily: "Poppins",
              }}
            >
              05 Feb 2023, 08:28 PM
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {viewCompletedOrder &&
              viewCompletedOrder?.order_details?.map((elem, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e3e3e3",
                        paddingBottom: "18px",
                      }}
                    >
                      <Box sx={{}}>
                        <img
                          src={`https://api.yandi.app${elem?.menu?.cover_img}`}
                          alt="order"
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "calc(100% - 100px)",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Box className="orderDesc">
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#000",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {elem?.menu?.item_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            {`GNF ${elem?.menu?.price}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#797B7E",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                            }}
                          >
                            Qty: {elem?.qty}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default RestaurauntCompleted;
