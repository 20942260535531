import { Box, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import AddAction from "../../../../Components/AddAction/AddAction";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSearchBox from "../../../../Components/CustomSearch/CustomSearch";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import AxiosInstance from "../../../../Config/AxiosInstance";
import useDebounce from "../../../../Components/useDebounce/useDebounce";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
const RestaurantsManagement = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchRestaurant, setSearchRestaurant] = useState("");
  const [restaurantManagementList, setRestaurantManagementList] = useState([]);
  const [hasMore] = useState(true);
  const [moreData, setMoreData] = useState(true);
  const debouncedSearch = useDebounce(searchRestaurant, 500);
  const countRef = useRef();
  useEffect(() => {
    countRef.current = 0;
    getRestaurantManagement();
  }, [debouncedSearch]);
  const getRestaurantManagement = async () => {
    try {
      const res = await AxiosInstance.get("/user/get-all", {
        params: {
          role: 5,
          count: countRef.current,
          search: debouncedSearch,
        },
      });
      if (res?.data?.s === 1) {
        const newItems = res?.data?.r;
        if (newItems.length > 0 && res.status === 200) {
          setMoreData(true);
          if (countRef.current !== 0) {
            setRestaurantManagementList((prevList) => [
              ...prevList,
              ...newItems,
            ]);
          } else {
            setRestaurantManagementList(newItems);
          }
        } else {
          setMoreData(false);
        }
        setIsLoading(false);
      } else {
        toast.error(res?.data?.m);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      toast.error(error?.response?.data?.m);
    }
  };
  const getRestaurantManagementMore = async () => {
    try {
      const newCount = countRef.current + 30;
      const res = await AxiosInstance.get("/user/get-all", {
        params: {
          role: 5,
          count: newCount,
          search: debouncedSearch,
        },
      });
      if (res?.data?.s === 1) {
        const newItems = res?.data?.r;
        if (newItems.length > 0 && res.status === 200) {
          setRestaurantManagementList((prevList) => [...prevList, ...newItems]);
          countRef.current += newItems.length;
          if (newItems.length < 30) {
            setMoreData(false);
          }
        } else {
          setMoreData(false);
        }
      } else {
        toast.error(res?.data?.m);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.m);
    }
  };
  const handleDeleteRestaurants = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this restaurant?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      });
  
      if (result.isConfirmed) {
        const response1 = await AxiosInstance.get("/admin/restaurant/get-all-order", {
          params: {
            restaurant_id: id,
            status: 0,
            order_status: 0,
          },
        });
  
        if (response1?.data?.s === 1) {
          const order = response1?.data?.r;
          if (order?.length > 0 && order.some(o => o?.order_status !== 0 || o?.status !== 0)) {
            toast.error("Cannot delete restaurant as it has active orders or unprocessed statuses.");
            return;
          } else {
            const formData = new FormData();
            formData.append("user_id", id);
            formData.append("status", 0);
  
            const response = await AxiosInstance.post(`/user/account-status`, formData);
            if (response?.data?.s === 1) {
              toast.success("Restaurant deleted successfully.");
              getRestaurantManagement();
            } else {
              toast.error("Failed to delete restaurant.");
            }
          }
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        toast.info("Restaurant deletion was canceled.");
      }
    } catch (error) {
      console.error("Error deleting restaurant:", error);
      toast.error("An error occurred while attempting to delete the restaurant.");
    }
  };
  
  
  return (
    <Box sx={{ padding: "0px 15px" }}>
      <AddAction
        title={"Restaurant Management"}
        btnTitle={"Add Restaurant"}
        onClick={() => navigate("/admin/restaurant/add")}
        showButton={true}
      />
      <Box sx={{ padding: "30px 0px 0px 0px" }}>
        <CustomSearchBox
          placeholder="Search Restaurant"
          value={searchRestaurant}
          onChange={(e) => setSearchRestaurant(e.target.value)}
          onClear={() => setSearchRestaurant("")}
        />
      </Box>
      <Box
        sx={{
          padding: "20px 0px 0px 0px",
        }}
      >
        <InfiniteScroll
          dataLength={restaurantManagementList.length}
          next={getRestaurantManagementMore}
          hasMore={hasMore && moreData}
          height={"calc(100vh - 195px)"}
          loader={
            isLoading ? (
              <Grid container spacing={3}>
                {Array.from(Array(3).keys()).map((idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                    key={idx}
                  >
                    <Box
                      sx={{
                        background: isLoading ? "#f7f7f7" : "#142328",
                        padding: "15px",
                        margin: "20px 10px 0px 0px",
                        borderRadius: "10px",
                        height: "160px",
                        position: "relative",
                      }}
                    >
                      <Skeleton
                        width={100}
                        height={100}
                        style={{ borderRadius: "10px" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          width: "calc(100% - 110px)",
                          marginLeft: "10px",
                        }}
                      >
                        <Skeleton height={20} style={{ marginBottom: "5px" }} />
                        <Skeleton height={20} style={{ marginBottom: "5px" }} />
                        <Skeleton height={20} style={{ marginBottom: "5px" }} />
                        <Skeleton height={20} style={{ marginBottom: "5px" }} />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : null
          }
        >
          <Grid
            container
            spacing={2}
            sx={{
              paddingRight: "10px",
            }}
          >
            {isLoading ? (
              Array.from(Array(9).keys()).map((idx) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  xxl={4}
                  key={idx}
                >
                  <Box
                    sx={{
                      background: isLoading ? "#f7f7f7" : "#142328",
                      padding: "15px",
                      borderRadius: "10px",
                      height: "160px",
                      position: "relative",
                    }}
                  >
                    <Skeleton
                      width={100}
                      height={100}
                      style={{ borderRadius: "10px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "calc(100% - 110px)",
                        marginLeft: "10px",
                      }}
                    >
                      <Skeleton height={20} style={{ marginBottom: "5px" }} />
                      <Skeleton height={20} style={{ marginBottom: "5px" }} />
                      <Skeleton height={20} style={{ marginBottom: "5px" }} />
                      <Skeleton height={20} style={{ marginBottom: "5px" }} />
                    </Box>
                  </Box>
                </Grid>
              ))
            ) : restaurantManagementList?.length > 0 ? (
              restaurantManagementList?.map((elem, idx) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={4}
                  xxl={4}
                  key={idx}
                >
                  <Box
                    sx={{
                      height: "200px",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#142328",
                        padding: "15px",
                        borderRadius: "10px",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          justifyContent: "center",
                          paddingTop: "35px",
                          alignItems: "center",
                          paddingTop: "50px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <img
                            srcSet={`https://api.yandi.app${elem?.profile_img}`}
                            alt="restaurantImage"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            width: "calc(100% - 110px)",
                            marginLeft: "10px",
                          }}
                        >
                          <Tooltip
                            title={elem?.restaurant_name}
                            placement="top"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#000",
                                  padding: "8px",
                                  fontSize: "14px",
                                  "& .MuiTooltip-arrow": {
                                    color: "#000",
                                  },
                                },
                              },
                            }}
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#ffffff",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#ffffff",
                                wordBreak: "break-all",
                                lineHeight: "19px",
                                marginBottom: "8px",
                              }}
                            >
                              {elem?.restaurant_name.length > 0
                                ? elem?.restaurant_name.slice(0, 50) + "..."
                                : elem?.restaurant_name}
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            title={elem?.description}
                            placement="top"
                            arrow
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#000",
                                  padding: "8px",
                                  fontSize: "14px",
                                  "& .MuiTooltip-arrow": {
                                    color: "#000",
                                  },
                                },
                              },
                            }}
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#ffffff",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#ffffff",
                                wordBreak: "break-all",
                              }}
                            >
                              {elem?.description.length > 0
                                ? elem?.description.slice(0, 150) + "..."
                                : elem?.description}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "110px",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        onClick={() => handleDeleteRestaurants(elem?.id)}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: "20px",
                            color: "red",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        onClick={() =>
                          navigate("/admin/restaurant/update", {
                            state: {
                              restuarantId: elem?.id,
                              type: "edit",
                            },
                          })
                        }
                      >
                        <EditIcon
                          sx={{
                            fontSize: "20px",
                            color: "#000000",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "60px",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                        onClick={() =>
                          navigate("/admin/restuarant/view", {
                            state: {
                              restaurantId: elem?.id,
                            },
                          })
                        }
                      >
                        <RemoveRedEyeIcon
                          sx={{
                            fontSize: "20px",
                            color: "#000000",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography variant="body2">
                No Restaurant Management Found
              </Typography>
            )}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default RestaurantsManagement;
