import { Box, Typography, Grid, IconButton } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
const PartnerPriceManagement = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px 15px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#161616",
          fontSize: "26px",
          fontWeight: "700",
          margin: "0px",
          padding: "0px",
          fontFamily: "Poppins",
          textAlign: "left",
          paddingBottom: "27px",
        }}
      >
        Price Management
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6} xl={4}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 4px 11.4px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "10px",
              padding: "20px 20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "0px 0px 15px 0px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#161616",
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px",
                  padding: "0px",
                  fontFamily: "Poppins",
                }}
              >
                Default partnership ratio
              </Typography>
              <IconButton
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  transition: "0.5s",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <EditIcon
                  sx={{
                    color: "#142328",
                    fontSize: "26px",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flexDirection: "column",
                paddingTop: "15px",
                gridRowGap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  Between Partner and Driver
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "5px 10px",
                    fontFamily: "Poppins",
                    background: "rgba(0, 230, 118, 0.25)",
                    borderRadius: "5px",
                  }}
                >
                  20$
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gridGap: "10px",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#161616",
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "0px",
                    padding: "0px",
                    fontFamily: "Poppins",
                    textAlign:'left'
                  }}
                >
                  <Typography variant="body1" component={"span"} sx={{
                    color:'red'
                  }}>
                    *
                  </Typography>
                  This will be the default partnership ratio between users if
                  you want specific partnership ratio you can update it from
                  user profile
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerPriceManagement;
